import { Form, Select, Spin, Input } from 'antd';
import _ from 'underscore';
import { useTranslation } from "react-i18next";
import { Pagination, Refund } from '../../components';
import './Refunds.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRefunds, setFilter } from '../../redux/refundSlice';

function Refunds() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { t } = useTranslation('translation');
  const dispatch = useDispatch();
  const { filter, refunds } = useSelector((state) => state.refunds);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchForm] = Form.useForm();

  const handleFilter = (payload) => {
    dispatch(setFilter(payload));
  };
  

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let isCancelled = false;
    const fetchData = async () => {
      try {
        setLoading(true);
  
        await dispatch(fetchRefunds({ filter, signal }));
        if (!isCancelled) {
          setLoading(false); 
        }
      } catch (error) {
        if (signal.aborted) {
          console.log('Request canceled:', error.message);
        } else {
          console.error('Error:', error);
        }
        if (!isCancelled) {
          setLoading(false); 
        }
      }
    };
  
    fetchData();
  
    return () => {
      isCancelled = true;
      controller.abort();
    };
  
  }, [filter, dispatch]);

  return (
    <div className="list-refunds-container">
      <div className="list-refunds-nav">
        <h2>Returns</h2>
      </div>
      <div className="list-refunds-filter">
        <div className="refund-search"> 
          <Form form={searchForm} onFinish={() => handleFilter({...filter , search_key : searchText})}>
            <Form.Item name="search">
              <Input value={searchText} placeholder={t('placeholders.Search')} onChange={(e) => setSearchText(e.target.value)} />
            </Form.Item>
          </Form>
        </div>
        <Form className='refund-filter-form'
          form={form}
          layout="inline"
        > 
          <Form.Item className="filter-refunds">
            <Select
              onChange={(value) => {
                let operationStatusValue;

                // Mapping values to keys in the database
                switch (value) {
                  case 'Taken From Customer':
                    operationStatusValue = 'taken_from_customer';
                    break;
                  case 'Send to Vendor':
                    operationStatusValue = 'send_to_vendor';
                    break;
                  case 'Taken From Vendor':
                    operationStatusValue = 'taken_from_vendor';
                    break;
                  case 'Send to Customer':
                    operationStatusValue = 'send_to_customer';
                    break;
                  default:
                    operationStatusValue = null;
                }

                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'operation_info'))});

                } else {
                  handleFilter({
                    ...filter,
                    filter: {
                      ...filter.filter,
                      operation_info: {
                        [operationStatusValue]: true,
                      },
                    },
                  });
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Operation Status</Option>
              <Option value="Taken From Customer">Taken From Customer</Option>
              <Option value="Send to Vendor">Send to Vendor</Option>
              <Option value="Taken From Vendor">Taken From Vendor</Option>
              <Option value="Send to Customer">Send to Customer</Option>
            </Select>
          </Form.Item>
          <Form.Item className="filter-refunds">
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'invoice_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, invoice_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Vendor Return Invoice</Option>
              <Option value=''></Option>
              <Option value="Refund Completed">Invoice Completed</Option>
              <Option value="Refund Needed">Invoice Needed</Option>
              <Option value='Refund Not Needed'>Invoice Not Needed</Option>
            </Select>
          </Form.Item>
          <Form.Item className="filter-refunds">
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'vendor_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, vendor_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Vendor Status</Option>
              <Option value=''></Option>
              <Option value='Product Exist'>Product Exist</Option>
              <Option value="Vendor Pending">Vendor Pending</Option>
              <Option value='Vendor Disapproved'>Vendor Disapproved</Option>
              <Option value="Vendor Approved">Vendor Approved</Option>
            </Select>
          </Form.Item>
          <Form.Item className="filter-refunds">
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'refund_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, refund_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Product Status</Option>
              <Option value='Submitted'>Submitted</Option>
              <Option value='Disapproved'>Disapproved</Option>
              <Option value="Approved">Approved</Option>
              <Option value="On Hold">On Hold</Option>                    
              <Option value="Completed">Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item className="filter-refunds">
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'order_refund_status'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, order_refund_status: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Order Status</Option>
              <Option value='Submitted'>Submitted</Option>
              <Option value="Customer Pending">Customer Pending</Option>
              <Option value="Vendor Pending">Vendor Pending</Option>
              <Option value="Operations Pending">Operations Pending</Option>
              <Option value='Disapproved'>Disapproved</Option>
              <Option value='Approved'>Approved</Option>
              <Option value='Completed'>Completed</Option>
            </Select>
          </Form.Item>
          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'customer_return_invoice'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, customer_return_invoice: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Customer Return Invoice</Option>
                <Option value=''></Option>
                <Option value='Refund Needed'>Invoice Needed</Option>
                <Option value='Refund Not Needed'>Invoice Not Needed</Option>
                <Option value='Refund Completed'>Invoice Completed</Option>
              </Select>
          </Form.Item>
          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'vendor_stock_status'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, vendor_stock_status: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Vendor Stock Status</Option>
                <Option value=''></Option>
                <Option value='Will Be Stock In'>Will Be Stock In</Option>
                <Option value="Will Be Sent to Vendors">Will Be Sent to Vendors</Option>
                <Option value="Will Be Held">Will Be Held</Option>
              </Select>
          </Form.Item>

          <Form.Item className='filter-refund-products'>
              <Select
                onChange={(value) => {
                  if (value === 'all') {
                    handleFilter({...filter , filter : (_.omit(filter.filter,'operation_stock_status'))});
                  } else {
                    handleFilter({ ...filter, filter : {...filter.filter, operation_stock_status: value }});
                  }
                }}
                defaultValue='all' 
              >
                <Option value='all'>Operation Stock Status</Option>
                <Option value=''></Option>
                <Option value='Stocked In'>{t("exchanges.operation_stock_status_stock_in")}</Option>
                <Option value="Not Stocked In">{t("exchanges.operation_stock_status_stock_out")}</Option>
              </Select>
          </Form.Item>
          <Form.Item className='filter-refund-products'>
            <Select
              onChange={(value) => {
                if (value === 'all') {
                  handleFilter({...filter , filter : (_.omit(filter.filter,'region'))});
                } else {
                  handleFilter({ ...filter, filter : {...filter.filter, region: value }});
                }
              }}
              defaultValue='all' 
            >
              <Option value='all'>Region</Option>
              <Option value="Europe">Europe</Option>
              <Option value="Other Europe">Other Europe</Option>
              <Option value='Turkey'>Turkey</Option>
              <Option value='Other Turkey'>Other Turkey</Option>
              <Option value='Others'>Others</Option>
              <Option value='Neighbor'>Neighbor</Option>
            </Select>
          </Form.Item>   
          <Form.Item name="sort" className='sort-filter'>
            <Select
              onChange={(e) => {
                const newValue = JSON.parse(e);
                handleFilter({...filter , sort: newValue});
              }}  
              defaultValue='{"order_delivery_date":-1}'
            >
              <Option value='{"order_delivery_date":-1}'>{t('fields.order_status.Delivery')}: {t('sort.New to Old')}</Option>
              <Option value='{"order_delivery_date":1}'>{t('fields.order_status.Delivery')}: {t('sort.Old to New')}</Option>
              <Option value='{"refund_request_date":-1}'>Request: {t('sort.New to Old')}</Option>
              <Option value='{"refund_request_date":1}'>Request: {t('sort.Old to New')}</Option>
              
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-refund-table-head">
        <div className="list-refund-table-order">Order</div>
        <div className="list-refund-table-customer">Customer</div>
        <div className="list-refund-table-count">Return Count</div>
        <div className="list-refund-table-status">Status</div> 
        <div className="list-refund-table-status">Order Delivery Date</div> 
        <div className="list-refund-table-status">Request Date</div> 
      </div>
      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Refunds..."} />}

      {!loading && refunds?.map((item) => (
        <Refund key={item._id} item={item} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={handleFilter} />
      </div>
    </div>
  );
}

export default Refunds;
