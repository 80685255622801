import { fetchVendors } from '../../redux/vendorSlice';
import { useDispatch, useSelector,u } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Vendors.css';

import { Button, Select, Spin } from 'antd';
import { SearchBar, Vendor } from '../../components';

function Vendors() {
  const { Option } = Select;

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { vendors, filter } = useSelector((state) => state.vendors);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(fetchVendors(filter));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [dispatch, filter]);

  const handleFilter = (value) => dispatch(fetchVendors(value));
  
  return (
    <div className="list-vendors-container">
      <div className="list-vendors-nav">
        <h2>Vendors</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text" onClick={() => {navigate("/vendor/new")}}>Add New Vendor</Button>
        </div>
      </div>
      <div className="list-vendors-filter">
        <div className='search'>
          <SearchBar type="vendor"/>
        </div>
        <div className='list-vendors-dropdown-filter'>
        <Select
          onChange={(e) => {
            const value = JSON.parse(e);
            handleFilter(value);
          }}
          defaultValue="{}"
        >
          <Option value="{}">Status</Option>
          <Option value='{"status":"Active"}'>Active</Option>
          <Option value='{"status":"Passive"}'>Passive</Option>
          <Option value='{"status":"Archived"}'>Archived</Option>
        </Select>

        <Select
          onChange={(e) => {
            const value = JSON.parse(e);
            handleFilter(value);
          }}
          defaultValue="{}"
        >
          <Option value="{}">Is Cerebro</Option>
          <Option value='{"is_cerebro":true}'>True</Option>
          <Option value='{"is_cerebro":false}'>False</Option>
        </Select>
        </div>
        
      </div>
      <div className="list-vendors-table-head">
        <div className="list-vendors-table-check">*</div>
        <div className="list-vendors-table-vendor">Vendor</div>
        <div className="list-vendors-table-status">Status</div>
        <div className="list-vendors-table-handle">Handle</div>
        <div className="list-vendors-table-user">User</div>
      </div>
      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Vendors..."} />}
      {vendors.map((item) => (
        <Vendor key={item._id} item={item}/>
      ))}
    </div>
  );

}

export default Vendors;
