const checkUpdateValidity = (configs, property, value, index) => {
    const updateInfo = {
        valid: true,
        message: null
    };

    if (property === 'secondary_quantity_threshold' && (value >= configs[index].quantity_threshold || value <= configs[index].tertiary_quantity_threshold)) {
        updateInfo.valid = false;
        updateInfo.message = "The Second Threshold Must Be Less Than The First Threshold and Greater Than The Third Threshold";
    } else if (property === 'quantity_threshold' && (value <= configs[index].secondary_quantity_threshold || value <= configs[index].tertiary_quantity_threshold)) {
        updateInfo.valid = false;
        updateInfo.message = "The First Threshold Must Be Greater Than The Second Threshold and The Third Threshold";
    } else if (property === "tertiary_quantity_threshold" && (value >= configs[index].quantity_threshold || value >= configs[index].secondary_quantity_threshold)) {
        updateInfo.valid = false;
        updateInfo.message = "The Third Threshold Must Be Less Than The First Threshold and The Second Threshold";
    }

    return updateInfo;
}

export {
    checkUpdateValidity
}