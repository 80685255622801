import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import store from './store';
import { getUrlFromState } from './userSlice';


export const fetchFieldDicts = createAsyncThunk(
  'field-dicts/fetchFieldDicts',
  async (filter) => {
    const url = getUrlFromState(store)
    const { data } = await axios.post(`${url}/field-dicts`, {
      filter,
    });
    return data;
  },
);

export const fetchUpdateFieldDicts = (updates) => {
  const url = getUrlFromState(store);
  return axios.patch(`${url}/field-dicts/update-many`, { updates });
};

const fieldDictsSlice = createSlice({
  name: 'fieldDicts',
  initialState: {
    filter: { page: 1, limit: 100, query: { blacklist: { $ne: true } } },
    fieldDicts: [],
    status: {
      fetchFieldDicts: 'idle',
    },
    error: null,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: {
    [fetchFieldDicts.pending]: (state) => {
      state.status.fetchFieldDicts = 'pending';
    },
    [fetchFieldDicts.fulfilled]: (state, action) => {
      state.status.fetchFieldDicts = 'succeeded';
      state.fieldDicts = action.payload;
    },
    [fetchFieldDicts.rejected]: (state, action) => {
      state.status.fetchFieldDicts = 'rejected';
      state.error = action.payload;
    },
  },
});

export const { setFilter } = fieldDictsSlice.actions;

export default fieldDictsSlice.reducer;
