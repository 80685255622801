import React from 'react';
import { Form, Input, notification, InputNumber } from 'antd';
import { loncaFormatDate } from '../../helpers';

const NameInputs = ({ product, setProduct, setIsDirty, createNameWithoutSku, handle, vendors, skuCode, isAllowed }) => {

    const addNew = handle === "new";

    const createSKU = (handle, vendor, vendors) => {
        const selectedVendor = vendors?.find(v => v?._id?.toString() === (vendor?._id || vendor)?.toString());
        if (handle === "new") {
          return `${selectedVendor?.vendor_initial || ""}${(selectedVendor?.vendor_product_count || 10000) + 1}`;
        } else {
          const isOld = !isNaN(handle?.split("-")[0][0]);
          const current_count = isOld ? handle?.split("-")[0] : handle?.split("-")[0]?.slice(3);
          return `${!isOld && selectedVendor?.vendor_initial ? selectedVendor?.vendor_initial : ""}${current_count}`;
        }
    }

    const createNameWithSku = (handle, name) => {
        let sku = createSKU(handle, product?.vendor, vendors);
        return sku + " - " + name;
    }

    const handleInputChange = (e) => {
        if (!product?.vendor) {
        notification['error']({
            message: 'You should specify vendor info first',
        });
        return;
        }

        setProduct({
        ...product,
        names: {
            ...product.names,
            tr: createNameWithSku(handle, e.target.value),
        },
        });
        setIsDirty(true);
    };

    return (

        <div>
            <div className="inline-inputs">
                <Form.Item label="Tr Name">
                    <Input
                    value={product?.names?.tr}
                    disabled
                    />
                </Form.Item>
                <Form.Item label='Raw Tr Name'>
                    <Input
                        value={createNameWithoutSku(product?.names?.tr)}
                        required
                        onChange={handleInputChange}
                    />
                </Form.Item>
            </div>
            <div className="inline-inputs">
                {addNew && <Form.Item label="SKU Code">
                  <Input
                    value={skuCode}
                    disabled
                  />
                </Form.Item>}
                {isAllowed && <Form.Item label="Ranking" name="ranking">
                  <InputNumber
                    value={product?.ranking}
                    min={0}
                    max={1000}
                    disabled
                    className="input-width"
                  />
                </Form.Item>}
            </div>
            <div className="inline-inputs">
               <Form.Item label="Created At">
                  <Input
                    value={loncaFormatDate(product?.createdAt)}
                    disabled
                  />
              </Form.Item>
            </div>

            <Form.Item name="supplier_link" label="Supplier Link">
                <Input
                  value={product?.supplier_link}
                  min={0}
                  onChange={(e) => {
                    const supplier_link = e.target.value
                    setProduct({ ...product, supplier_link });
                    setIsDirty(true);
                  }}
                  className="input-width"
                  disabled={!isAllowed}
                />
              </Form.Item>

              <Form.Item name="supplier_stock_code" label="Supplier Stock Code">
                <Input
                  value={product?.supplier_stock_code}
                  min={0}
                  onChange={(e) => {
                    const supplier_stock_code = e.target.value
                    setProduct({ ...product, supplier_stock_code });
                    setIsDirty(true);
                  }}
                  className="input-width"
                  disabled={!isAllowed}
                />
              </Form.Item>
        </div>
        

        
        
    );
};

export default NameInputs;
