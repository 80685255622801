import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInvoiceFilter } from '../../redux/filterSlice';
import './Invoices.css';

import { Button, Select, Form } from 'antd';
import { LocalSearch, Invoice, EditInvoice, Pagination } from '../../components';
import { fetchInvoices, fetchAllInvoices } from '../../redux/invoiceSlice';
import { listOrders } from '../../redux/orderSlice';
import { ADMIN_VALID_CUSTOMER_INVOICE_ORDER_STATUSES } from '../../constants';

function Invoices() {
  const dispatch = useDispatch();
  const { invoiceFilter: filter } = useSelector((state) => state.filter);

  const [invoices, setInvoices] = useState([]);
  const [allInvoices, setAllInvoices] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [invoice, setInvoice] = useState(null);
  const [newInvoice, setNewInvoice] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [addNew, setAddNew] = useState(false);

  const { Option } = Select;
  const [form] = Form.useForm();

  const setFilter = useCallback((value) => {
    dispatch(setInvoiceFilter(value));
  }, [dispatch]);

  useEffect(() => {
    setFilter({ page: 1, limit: 100, sort: {}, filter: {} })
  }, [setFilter]);

  useEffect(() => {
    fetchInvoices(filter)
      .then((res) => setInvoices(res.data))
      .catch((err) => console.log(err));
  }, [filter, showForm, refresh]);

  useEffect(() => {
    fetchAllInvoices()
      .then((res) => {
        setAllInvoices(res.data)
      })
      .catch((err) => console.log(err));
      listOrders({
				filter : {
					filter: { status: { $in: ADMIN_VALID_CUSTOMER_INVOICE_ORDER_STATUSES } },
					sort : { payment_at : -1 }
				  },
				mode : "admin_list"
				})
      .then((res) => {
        const duplicates = res?.data?.data?.map((i) => i.customer);
        setCustomers(duplicates)
      })
      .catch((err) => console.log(err));
  }, [])

  if(!showForm) return (
    <div className="list-invoices-container">
      <div className="list-invoices-nav">
        <h2>Invoices</h2>
        <div>
          <Button type="text">Export</Button>
          <Button type="text">Import</Button>
          <Button type="text" onClick={() => {
            setAddNew(true);
            setShowForm(true);
          }}>Add New Invoice</Button>
        </div>
      </div>
      <div className="list-invoices-filter">
        <div className="search">
          <LocalSearch items={allInvoices} type="invoice" refresh={refresh} setRefresh={setRefresh} setShowForm={setShowForm} baseItems={invoices.slice()} setItem={setInvoice} setItems={setInvoices}/>
        </div>
        <Form
          className="invoice-filter-form"
          layout="inline"
          form={form}
          initialValues={{
            customer: "all",
            sort: "all"
          }}
        >
          <Form.Item name="customer">
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  setFilter({ ...filter, filter: {} });
                } else {
                  setFilter({ ...filter, filter: { customer: e } });
                }
              }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ width: "150px" }}
            >
              <Option value="all">All Customers</Option>
              {customers?.map((customer, index) => (
                <Option key={'customer-select' + index} value={customer._id}>
                  {customer.firstName + " " + customer.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="sort">
            <Select
              onChange={(e) => {
                if (e === 'all') {
                  setFilter({ ...filter, sort: {} });
                } else {
                  setFilter({ ...filter, sort: { issue_date: e } });
                }
              }}
              style={{ width: "150px" }}
            >
              <Option value="all">Sort</Option>
              <Option value="-1">Date: New to Old</Option>
              <Option value="1">Date: Old to New</Option>
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="list-invoices-table-head">
        <div className="list-invoices-table-check">*</div>
        <div className="list-invoices-table-code">Code</div>
        <div className="list-invoices-table-customer">Customer</div>
        <div className="list-invoices-table-issuedate">Issue Date</div>
        <div className="list-invoices-table-subcost">Subtotal Cost</div>
        <div className="list-invoices-table-discount">Discount</div>
        <div className="list-invoices-table-totcost">Total Cost</div>
      </div>
      {invoices.map((invoice_item) => (
        <Invoice key={invoice_item._id} item={invoice_item} setInvoice={setInvoice} setShowForm={setShowForm} />
      ))}
      <div>
        <Pagination filter={filter} setFilter={setFilter}/>
      </div>
    </div>
  );

  if(showForm) return (
    <EditInvoice invoice={addNew ? newInvoice : invoice} setInvoice={addNew ? setNewInvoice : setInvoice} setShowForm={setShowForm} addNew={addNew} setAddNew={setAddNew}/>
  );
}

export default Invoices;
