import React, { useState } from "react";
import "./BulkInvoicePopup.css";
import { Button, DatePicker, Form, Input, Select, notification } from "antd";
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import FormItem from "antd/lib/form/FormItem";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { loncaRound, getBase64, generateZip } from "../../../helpers";
import { fetchUploadUrl } from "../../../redux/productSlice";

const BulkInvoicePopup = ({ 
    handleBulkOperations, 
    setIsBulkInvoicePopupOpen, 
    shipmentDate, 
    setShipmentDate, 
    billOfLading, 
    setBillOfLading,
    bulkCargoCompany,
    bulkCargoCompanies,
    setBulkCargoCompany,
    numBulkOrders,
    shipmentCost,
    setShipmentCost,
    shipmentCostLocal,
    setShipmentCostLocal,
    currencies,
    currency,
    setCurrency,
    invoice,
    setInvoice,
    realInfo
}) => {

    const { t } = useTranslation("translation");
    const [buttonsLoading, setButtonsLoading] = useState(false);
    const [invoiceLinks, setInvoiceLinks] = useState([]);
    const [invoiceThumbs, setInvoiceThumbs] = useState([]);
    const [invoiceNames, setInvoiceNames] = useState([]);

    const handleSubmit = async () => {
        if(!shipmentDate || !billOfLading || !bulkCargoCompany || !shipmentCost || !shipmentCostLocal) {
            notification['error']({
                message: "Please provide a shipment date and/or a bill of lading!"
            });
            return;
        }

        setButtonsLoading(true);
        await handleBulkOperations("bulkShipment");
        setBillOfLading("");
        setShipmentDate(null);
        setBulkCargoCompany("");
        setIsBulkInvoicePopupOpen(false);
        setShipmentCost(0);
        setShipmentCostLocal(0);
        setInvoice([]);
    }

    const handleCancel = () => {
        setBillOfLading("");
        setShipmentDate(null);
        setBulkCargoCompany("");
        setIsBulkInvoicePopupOpen(false);
        setShipmentCost(0);
        setShipmentCostLocal(0);
        setInvoice([]);
    }

    const handleDownload = async () => {
        try {
            generateZip(invoiceLinks, invoiceNames.join("_invoices"));
        } catch(err) {
            console.log(err);
        }
    }

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        const newInvoiceNames = [];
        const newInvoiceLinks = [];
        const newInvoiceThumbs = [];

        for (const file of files) {
            newInvoiceNames.push(file.name);

            const thumb = await getBase64(file);
            newInvoiceThumbs.push(thumb);

            const extension = file?.type === "application/pdf" ? "pdf" : "jpg";

            const response = await fetchUploadUrl(`shipment-invoice/${file.name}-shipment.${extension}`, file?.type);
            await fetch(response.data, {
                method: "PUT",
                body: file,
            });
            newInvoiceLinks.push(response.data.split('?')[0]);
        }
        
        setInvoiceNames(prevNames => [...prevNames, ...newInvoiceNames]);
        setInvoiceThumbs(prevThumbs => [...prevThumbs, ...newInvoiceThumbs]);
        setInvoiceLinks(newInvoiceLinks);
        setInvoice(newInvoiceLinks);

        notification['success']({
            message: 'Invoices uploaded to AWS successfully',
        });
    }

    return (
        <Form onFinish={handleSubmit}>
            <div className="bulk-invoice-popup-container">
                <span className="bulk-invoice-popup-header">{t("fields.order_status.Create Bulk Shipment")}:</span>
                <div className="inline-three-inputs">
                    <FormItem label={t("fields.order_status.Shipment Date")} required>
                        <DatePicker
                            placeholder={t("fields.order_status.Shipment Date")}
                            showTime
                            value={shipmentDate ? moment(shipmentDate) : null}
                            onChange={(e) => {
                                setShipmentDate(e._d);
                            }}
                        />
                    </FormItem>
                    <FormItem label={t("fields.order_status.Bill of Lading (TR to NL)")} required>
                        <Input
                            placeholder={t("fields.order_status.Bill of Lading (TR to NL)")}
                            value={billOfLading}
                            onChange={(e) => setBillOfLading(e.target.value)}
                            required
                        />
                    </FormItem>
                    <FormItem label={t("fields.order_status.Real Cargo Company")} required>
                        <Select
                            onChange={setBulkCargoCompany}
                            placeholder={t("fields.order_status.Real Cargo Company")}
                        >
                            {
                                bulkCargoCompanies?.map((cargoCompany) => {
                                    return (
                                        <Option key={cargoCompany?._id} value={cargoCompany?._id}>
                                            {cargoCompany?.name}
                                        </Option>
                                    )
                                })
                            } 
                        </Select>
                    </FormItem>
                </div>
                
                <div className="inline-three-inputs">
                    <Form.Item label={t("fields.order_status.Shipment Cost") + ` (${currency?.symbol})`} required>
                        <Input
                            value={shipmentCostLocal}
                            onChange={(e) => {
                                setShipmentCost(loncaRound(e.target.value / currency?.rate_usd_buying));
                                setShipmentCostLocal(e.target.value);
                            }}
                            required
                        />
                    </Form.Item>
                    <FormItem label={t("fields.order_status.Shipment Cost") + ` ($)` } required>
                        <Input
                            placeholder={t("fields.order_status.Shipment Cost")}
                            value={shipmentCost}
                            required
                            disabled
                        />
                    </FormItem>
                    <Form.Item name="currency" label={t("fields.order_status.local_currency")}>
                        <></>
                        <Select
                            value={currency?.abbreviation}
                            onChange={(e) => {
                                const selectedCurrency = currencies?.find(c => c?.abbreviation === e);
                                setCurrency(selectedCurrency);
                            }}
                        >
                            {currencies?.map(currency => (
                                <Option value={currency?.abbreviation}>{currency?.abbreviation}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
                
                <div className="inline-three-inputs">
                    <Form.Item label={t("fields.edit_order.Rate Usd Selling") + ` (${currency?.symbol})`}>
                        <Input
                            value={`${currency?.rate_usd_buying}`}
                            disabled
                        />
                    </Form.Item>
                    <Form.Item label={t("fields.order_status.per_desi_cost") + ` ($)`}>
                        <Input
                            value={loncaRound(shipmentCost/realInfo?.desi) + "$ - " + loncaRound(shipmentCostLocal/realInfo?.desi) + `${currency?.symbol}`}
                            disabled
                        />
                    </Form.Item>
                    <Form.Item>
                        <label className="custom-file-upload">
                            <input type="file" multiple onChange={handleFileUpload} />
                            <UploadOutlined />
                            {"  Upload Invoices"}
                        </label>
                        <div className="invoice-img-container">
                            {invoiceThumbs.map((thumb, index) => (
                                <div key={index}>
                                    <img alt="invoice" width={100} src={thumb} />
                                    <div>{invoiceNames[index]}</div>
                                </div>
                            ))}
                        </div>

                        <Button onClick={handleDownload}>
                            <DownloadOutlined />
                            Download All Invoices
                        </Button>

                        
                    </Form.Item>
                </div>
                <div className="inline-two-inputs">
                    
                </div>
                
                
                <div className="bulk-invoice-popup-button-container">
                    <FormItem>
                        <Button type="primary" htmlType="submit" loading={buttonsLoading} disabled={numBulkOrders === 0}>
                            {t("fields.order_status.Submit")}
                        </Button>
                    </FormItem>
                    <FormItem>
                        <Button type="secondary" loading={buttonsLoading} onClick={handleCancel}>
                            {t("fields.order_status.Cancel")}
                        </Button>
                    </FormItem>
                </div>
            </div>
        </Form>
    );
}

export default BulkInvoicePopup;