import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Collapse, Row, Col, Input, Button } from 'antd';
import Shelve from './Shelve/Shelve';
import axios from "axios";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);
const { Panel } = Collapse;
const { Search } = Input;

import "./Shelves.scss";

import { fetchWarehouseBoxes, fetchUpdateWarehouseBox } from "../../redux/warehouseBoxSlice";
import { fetchOrderAggregate } from '../../redux/orderSlice';
import { groupShelvesByRowLength } from '../../helpers';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import BarChart from './BarChart';
import AddNewBoxPopup from './AddNewBoxPopup/AddNewBoxPopup';
const ANTD_GRID_CONSTANT = 24

let getShelfType = (tab) => {
    if(tab === "order-box") return "order";
    else if(tab === "stock-box") return "stock";
    else if(tab === "exchange-box") return "exchange";
    else return null;
}

let getShelveConfigs = ({ tab }) => {
    if (tab === 'stock-box') {
        return [
            { name: 'S', row_length: 8 },
        ];
    }
    else if (tab === 'order-box') {
        return [
            { name: 'A', row_length: 8 },
            { name: 'B', row_length: 8 },
            { name: 'C', row_length: 8 },
            { name: 'D', row_length: 8 },
            { name: 'E', row_length: 8 },
            { name: 'F', row_length: 8 },
            { name: 'G', row_length: 10 },
            { name: 'H', row_length: 10 },
            { name: 'J', row_length: 8 },
            { name: 'K', row_length: 8 }
        ];
    }
    else if(tab === 'exchange-box') {
        return [
            { name: 'Z', row_length: 8}
        ];
    }
}

const ShelvesPage = () => {
    const { t } = useTranslation('translation');
    const [searchParams, _] = useSearchParams();
    const [warehouseBoxes, setWarehouseBoxes] = useState([]);
    const [boxesCache, setBoxesCache] = useState(warehouseBoxes);
    const [fetchBoxesToggle, setFetchBoxesToggle] = useState(null);
    const [orders, setOrders] = useState([])
    const [exchangeOrders, setExchangeOrders] = useState([]);
    const [isAddNewBoxPopupOpen, setIsAddNewBoxPopupOpen] = useState(false);

    const [boxInfos, setBoxInfos] = useState([{ empty: 0, notFull: 0, full: 0, disabled: 0, total: 0 }])
    const chartData = {
        labels: ['Empty', 'Full', 'Not Full', 'Disabled'],
        datasets: [
            {
                data: [boxInfos.empty, boxInfos.full, boxInfos.notFull, boxInfos.disabled],
                backgroundColor: ['#008000', '#FF0000', '#0000FF', '#808080'],
                hoverBackgroundColor: ['#008000', '#FF0000', '#0000FF', '#808080'],
            },
        ],
    };

    const navigate = useNavigate()
    const location = useLocation();
    const [tab, setTab] = useState('order-box')
    const shelveConfigs = getShelveConfigs({ tab });
    useEffect(() => {
        setTab(searchParams?.get("tab") || "order-box");
    }, [searchParams?.get("tab")]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (!orders?.length) {
            fetchOrderAggregate({
                mode: "warehouse_boxes",
                cancelToken: source.token
            })
                .then((res) => {
                    setOrders(res.data);
                })
                .catch((err) => console.log(err));
        }
    }, [orders])

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (!exchangeOrders?.length) {
            fetchOrderAggregate({
                mode: "exchange_boxes",
                cancelToken: source.token
            })
                .then((res) => {
                    setExchangeOrders(res.data);
                    console.log(res.data)
                })
                .catch((err) => console.log(err));
        }
    }, [])

    useEffect(() => {
        fetchWarehouseBoxes({})
            .then(res => {
                setWarehouseBoxes(res.data)
                setBoxesCache(res.data)
            })
            .catch((err) => console.log(err))
    }, [fetchBoxesToggle]);

    useEffect(() => {
        const boxInfos = { empty: 0, notFull: 0, full: 0, disabled: 0, total: 0 }
        const type = getShelfType(tab);
        const filteredWarehouseBoxes = warehouseBoxes.filter((box) => box?.type === type)

        for (let i = 0; i < filteredWarehouseBoxes.length; i++) {
            if (filteredWarehouseBoxes[i].is_disabled === true) {
                boxInfos.disabled = boxInfos.disabled + 1
            } else if (filteredWarehouseBoxes[i].is_full === true) {
                boxInfos.full = boxInfos.full + 1
            } else if (filteredWarehouseBoxes[i].unique_order_id || filteredWarehouseBoxes[i]?.products?.length > 0) {
                boxInfos.notFull = boxInfos.notFull + 1
            } else {
                boxInfos.empty = boxInfos.empty + 1
            }
            boxInfos.total = boxInfos.total + 1
        } setBoxInfos(boxInfos)
    }, [tab, warehouseBoxes]);

    const groupedBoxes = groupShelvesByRowLength(boxesCache, shelveConfigs);

    const handleSearch = (value) => {
        if (!value) {
            setBoxesCache(warehouseBoxes)
            return
        }
        const filtered = warehouseBoxes.filter(
            (shelf) =>
                shelf?.customer_name?.toLowerCase()?.includes(value?.toLowerCase()) || shelf?.unique_order_id?.includes(value)
        );
        setBoxesCache(filtered);
    };

    return (
        <div className='shelves-container'>
            {tab === "exchange-box" && isAddNewBoxPopupOpen && <AddNewBoxPopup setIsAddNewBoxPopupOpen={setIsAddNewBoxPopupOpen} exchangeOrders={exchangeOrders}/>}
            <div className="header-save shelves-header-save">
                <div className="shelves-tabs">
                    <div onClick={() => navigate(`${location.pathname}?tab=order-box`)} className={tab === "order-box" && "selected"}>Order Box</div>
                    <div onClick={() => navigate(`${location.pathname}?tab=stock-box`)} className={tab === "stock-box" && "selected"}>Stock Box</div>
                    <div onClick={() => navigate(`${location.pathname}?tab=exchange-box`)} className={tab === "exchange-box" && "selected"}>Return Box</div>
                </div>
            </div>
            <div className='shelves-top-container'>
                <h2 className=''>{t("shelves.Shelves")}</h2>
                <Search className='search-box'
                    placeholder={t("shelves.Search by customer name or unique order ID")}
                    onSearch={handleSearch}
                    enterButton
                />
                <BarChart data={chartData} />
            </div>
            {tab === "exchange-box" && <div className='add-new-box-container'>
                <Button onClick={() => setIsAddNewBoxPopupOpen(true)}>
                    {t('shelves.Add New Exchange Box')}
                </Button>
            </div>}
            <Collapse defaultActiveKey={['A', 'B', 'C', 'D', 'E', 'F', 'J', 'G', 'H', 'K', 'Z']}>
                {Object.keys(groupedBoxes).map((shelveName) => {
                    const shelveData = groupedBoxes[shelveName];

                    return (
                        <Panel header={shelveName} key={shelveName}>
                            {shelveData.rows.map((row, rowIndex) => (
                                <div key={shelveName + rowIndex}>
                                    <Row gutter={[15, 15]}>
                                        {row?.map((shelve, index) => (
                                            <Col span={ANTD_GRID_CONSTANT / shelveData.row_length} key={index}>
                                                <Shelve 
                                                    packagableOrders={orders} 
                                                    exchangeOrders={exchangeOrders}
                                                    warehouseBoxes={warehouseBoxes} 
                                                    shelve={shelve} 
                                                    shelveUpdate={fetchUpdateWarehouseBox} 
                                                    fetchBoxesToggle={fetchBoxesToggle} 
                                                    setFetchBoxesToggle={setFetchBoxesToggle}
                                                    tab={tab} 
                                                    />
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                            ))}
                        </Panel>
                    );
                })}
            </Collapse>
        </div>
    );
};

export default ShelvesPage;
