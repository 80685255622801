import { notification } from 'antd';

const moment = require('moment');

const ObjectID = require('bson-objectid');
const JSZip = require('jszip');
const saveAs = require('file-saver');
const {
  VAT,
  REGION_PRICE_COEFFICIENTS,
  ALLOW_AUTO_REFUND_STOCK_OUT_RATIO
} = require('../constants/index');
const { fetchUploadUrl } = require('../redux/productSlice');

const loncaRound = (number, decimal) => {
  if (isNaN(number)) return 0;
  if (isNaN(decimal) || !decimal) decimal = 2;

  number = parseFloat(number);
  decimal = parseInt(decimal, 10);

  return Math.round(number * 10 ** decimal) / 10 ** decimal;
};

function loncaFormatDate(date, preserveOriginalGMT) {
  if (date) {
    if(preserveOriginalGMT) return moment.utc(date).format('DD.MM.YYYY HH:mm');
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  return "";
}

function decodeTurkishCharacters(text) {
  return text
    .replace(/\ğ/g, "g")
    .replace(/\ü/g, "u")
    .replace(/\ş/g, "s")
    .replace(/\ı/g, "i")
    .replace(/\ö/g, "o")
    .replace(/\ç/g, "c")
    .replace(/\Ğ/g, "G")
    .replace(/\Ü/g, "U")
    .replace(/\Ş/g, "S")
    .replace(/\İ/g, "I")
    .replace(/\Ö/g, "O")
    .replace(/\Ç/g, "C");
}

function getDate(date) {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();
  if (isNaN(day) || isNaN(month) || isNaN(year)) return 'NaN';
  return `${day}/${month}/${year}`;
}

function getTime(date) {
  const newDate = new Date(date);
  const hours = newDate.getHours();
  const minutes = newDate.getMinutes();
  const seconds = newDate.getSeconds();
  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) return 'NaN';
  return `${hours}:${minutes}:${seconds}`;
}

function additionalServicesFilter(form, setFilter, filter, e, resetField, newFilter) {
  const newValues = form.getFieldsValue();
  newValues[resetField] = 'all';
  form.setFieldsValue(newValues);
  if (e === 'all') {
    const newFilters = filter;
    newFilters.filter = {};
    newFilters.filter[newFilter] = null;
    setFilter(newFilters);
  } else {
    const newFilters = filter;
    newFilters.filter = {};
    newFilters.filter[newFilter] = e;
    setFilter(newFilters);
  }
}

function getChangedValues(product) {
  const changed = {
    names: product.names,
    description_details: product?.description_details,
    cogs: isNaN(product.cogs) ? '0' : loncaRound(product.cogs),
    cogs_tr: isNaN(product.cogs_tr) ? '0' : loncaRound(product.cogs_tr),
    price: isNaN(product.price) ? '0' : loncaRound(product.price),
    price_tr: isNaN(product.price_tr) ? '0' : loncaRound(product.price_tr),
    discounted_cogs: isNaN(product.discounted_cogs) ? '0' : loncaRound(product.discounted_cogs),
    discounted_cogs_tr: isNaN(product.discounted_cogs_tr) ? '0' : loncaRound(product.discounted_cogs_tr),
    discounted_price: isNaN(product.discounted_price) ? '0' : loncaRound(product.discounted_price),
    discounted_price_tr: isNaN(product.discounted_price_tr) ? '0' : loncaRound(product.discounted_price_tr),
    is_discounted: !!product.is_discounted,
    is_discounted_tr: !!product.is_discounted_tr,
    handle: product.handle,
    status: product.status,
    product_type: product.product_type,
    color: product.color,
    gender: product.gender,
    vendor: product.vendor,
    series: product.series,
    tags: product.tags || [],
    supplier_stock_code: product?.supplier_stock_code,
    supplier_link: product?.supplier_link || "",
    hide_product: !!product?.hide_product,
    need_photo_shoot: product?.need_photo_shoot,
    update_config: product?.update_config || {},
    change_log: product?.change_log || [],
  };

  if (product.stock || product.stock === 0) changed.stock = product.stock;
  if (!product.stock) changed.stock = 0;

  if (product.supplier_stock
    || product.supplier_stock === 0) changed.supplier_stock = product.supplier_stock;
  if (!product.supplier_stock) changed.supplier_stock = 0;

  if (product.in_transit_stock
    || product.in_transit_stock === 0) changed.in_transit_stock = product.in_transit_stock;
  if (!product.in_transit_stock) changed.in_transit_stock = 0;

  if (product.reserved_stock
    || product.reserved_stock === 0) changed.reserved_stock = product.reserved_stock;
  if (!product.reserved_stock) changed.reserved_stock = 0;


  if (product?.measurement) {
    changed.measurement = product.measurement;
  }

  if(product?.sub_series?.length > 0){
    changed.sub_series = product?.sub_series;
  }

  return changed;
}

function convertToHandle(name) {
  if (!name) return '';
  name = name.split(/[^0-9A-Za-z]+/).join(' ');
  name = name.trim();
  name = name.replaceAll(' ', '-').toLowerCase();
  return name;
}

const calculateItemPrice = ({ item, order = false }) => {
  if (order) {
    return loncaRound(item?.price);
  }
  return item?.is_discounted
    ? loncaRound(item?.product?.discounted_price) : loncaRound(item?.product?.price);
};

const calculateFavoriteProductPrice = ({ item }) => {
  return item?.is_discounted
    ? loncaRound(item?.discounted_price) : loncaRound(item?.price);
};

const calculateItemPriceWithCogs = ({ item, order = false }) => {
  const cogs = order ? item?.cogs : item?.product?.cogs;
  const vat = item?.product?.product_type?.vat;

  return loncaRound(cogs * (1 + vat));
};

const calculateItemPriceWithVAT = ({ item, order = false }) => loncaRound(
  calculateItemPrice({ item, order }) * (1 + item.vat),
);

const calculatePriceBasedonRegion = (price, region) => {
  if (region && region in REGION_PRICE_COEFFICIENTS) {
    price = loncaRound(price * REGION_PRICE_COEFFICIENTS[region]);
  } else {
    price *= 1;
  }
  return price;
};

const productToOrderItem = (product, quantity, region) => {
  const item = {
    _id: ObjectID(),
    // product info
    series: product.series.name,
    product,
    variantId: product.variants[0],
    vendor: product?.vendor?._id,
    // product price info
    cogs: product?.is_discounted ? product?.discounted_cogs : product.cogs,
    quantity,
    item_count: product.series.item_quantity,
    order_status: 'Received',
    desi: (product?.measurement?.desi || product?.product_type?.desi),
    is_sample: false,
    vat: product?.product_type?.vat,
    price: calculatePriceBasedonRegion((
      product?.is_discounted ? product?.discounted_price : product?.price), region),
    full_price: calculatePriceBasedonRegion(product?.price, region),
    vendor_product_id: product?.vendor_product_id,
    is_discounted: product?.is_discounted,
  };

  return item;
};

const productToCartItem = (product, quantity) => {
  const item = {
    _id: ObjectID(),
    product,
    quantity,
  };

  return item;
};

const changeCartItem = ({ item, setItem, field, value, currentItem, series, isOrderStatus, initialItem, setError, owner }) => {
  let newValue;
  let statusLog;
  let confirm_info;
  let review_info;
  let is_counted_log;
  let source = isOrderStatus ? 'Admin - Order Products Page' : 'Admin - Edit Order Page';

  switch (field) {
    case 'quantity':
      newValue = value === '' ? 0 : parseInt(value, 10);
      break;

    case 'cogs':
      newValue = value === '' ? 0 : value;
      break;
    case 'series':
      newValue = value;
      break;
    case 'order_status':
      newValue = value;
      statusLog = { status: value, owner, date: new Date(), source };

      if (value === "Confirmed") {
        confirm_info = { owner, date: new Date(), source };
      }
      if (value === "Reviewed") {
        review_info = { owner, date: new Date(), source };
      }

      break;
    case 'item_count':
      newValue = value;
      break;
    case 'notes':
      newValue = value;
      break;
    case 'notes_admin':
      newValue = value;
      break;
    case 'entered_without_invoice':
      newValue = value;
      break;
    case 'is_counted':
      newValue = value;
      if (value === true) {
        is_counted_log = { owner, date: new Date(), source };
      }
      break;
    default:
      break;
  }

  if (isOrderStatus) {
    let newObj = { ...currentItem };
    newObj[field] = newValue;

    if (field === 'series') {
      newObj.item_count = series.find((i) => i.name === value).item_quantity;
    }


    newObj = updateObjectWithLogs(newObj, statusLog, confirm_info, review_info, initialItem, is_counted_log);

    setItem({
      ...item,
      cart_item: newObj,
    });

    return;
  }

  if (field === 'quantity') {

    if (currentItem?.order_status === 'StockIn') {
      if (newValue > currentItem?.product?.stock + initialItem?.quantity) {
        setError(true);
        return { stockError: true, maxStock: currentItem?.product?.stock + initialItem?.quantity };
      }
    } else {
      if (newValue > currentItem?.product?.supplier_stock + initialItem?.quantity) {
        setError(true);
        return { stockError: true, maxStock: currentItem?.product?.supplier_stock + initialItem?.quantity };
      }
    }
  }

  setItem({
    ...item,
    cart_item: item.cart_item.map((i) => {
      if (i._id === currentItem._id) {
        let newObj = { ...currentItem };
        newObj[field] = newValue;
        if (field === 'series') {
          newObj.item_count = series.find((fi) => fi.name === value).item_quantity;
        }
        if (field === 'order_status') {
          newObj = updateObjectWithLogs(newObj, statusLog, confirm_info, review_info, initialItem, is_counted_log);
        }
        if (field === 'is_counted') {
          newObj = updateObjectWithLogs(newObj, statusLog, confirm_info, review_info, initialItem, is_counted_log);
        }
        return newObj;
      }
      return i;
    }),
  });
};

const checkOrderStatus = (cartItem) => {
  let result = true;
  for (const item of cartItem) {
    if (item.order_status === 'Received') {
      result = false;
      break;
    }
  }
  return result;
};

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => resolve(reader.result);

  reader.onerror = (error) => reject(error);
});

const uploadMedias = async (vendor, fileList, sku, customIncrement = 0, type = "product", mediaType) => {
  const linkList = [];

  for (let index = 0; index < fileList.length; index += 1) {
    try {
      const replacedVendorName = type === "refund"
        ? vendor
        : vendor?.replaceAll(' ', '_');

      const response = await fetchUploadUrl(`${type}${mediaType === 'video' ? '/videos/raw' : ''}/${replacedVendorName}/${sku}-${index + 1 + customIncrement}`);
      await fetch(response.data, {
        method: 'PUT',
        body: fileList[index].originFileObj,
      });

      let imageUrl = response.data.split('?')[0]
      if(mediaType === 'video'){
        imageUrl = imageUrl?.replace('raw','compressed')
        imageUrl = imageUrl + '.mp4';
      }
      
      linkList.push(imageUrl);

      if (index === fileList.length - 1) return linkList;
    } catch (err) {
      console.error(err);
    }
  }

  return linkList;
};

const getImageUrl = (response) => {
  try {
    const imageUrl = response.data.split('?')[0];
    return imageUrl;
  } catch (err) {
    return '';
  }
};

const uploadChartImage = async (vendor, fileList, sku) => {
  try {
    const replacedVendorName = vendor?.replaceAll(' ', '_');
    const response = await fetchUploadUrl(`product/${replacedVendorName}/${sku}-${'size-chart-image'}`);
    await fetch(response.data, {
      method: 'PUT',
      body: fileList[0].originFileObj,
    });

    const imageUrl = response.data.split('?')[0];
    return imageUrl;
  } catch (err) {
    console.error(err);
    return '';
  }
};

const download = (zip, fileName) => {
  zip.generateAsync({ type: 'blob' }).then((blob) => {
    saveAs(blob, `${fileName}.zip`);
  });
};

export const groupShelvesByRowLength = (data, shelveConfigs) => {
  const result = {};

  // Initialize result object with shelve names
  shelveConfigs.forEach((shelveConfig) => {
    result[shelveConfig.name] = { rows: [] };
  });

  data.forEach((item) => {
    const shelveName = item.unit;
    const shelveConfig = shelveConfigs.find((config) => config.name === shelveName);

    if (shelveConfig) {
      // Determine the row index based on the number of rows already created
      let rowIndex;
      if (typeof item.unit_number === 'number') {
        rowIndex = Math.ceil(item.unit_number / shelveConfig.row_length);
      } else if (typeof item.unit_number === 'string') {
        const numericPart = parseFloat(item.unit_number);
        rowIndex = !isNaN(numericPart)
          ? Math.ceil(numericPart / shelveConfig.row_length)
          : null;
      } else {
        rowIndex = null; // Handle other types if necessary
      }

      if (rowIndex !== null) {
        // Create a new row if needed
        if (!result[shelveName].rows[rowIndex]) {
          result[shelveName].rows[rowIndex] = [];
        }

        // Push the current item into the appropriate row
        result[shelveName].rows[rowIndex].push(item);
      }
    }
  });

  // Sort rows
  Object.keys(result).forEach((shelveName) => {
    result[shelveName].rows = result[shelveName].rows.map((row) =>
      ['A', 'C', 'E', 'G'].includes(shelveName)
        ? row.sort((a, b) => b.unit_number - a.unit_number)
        : row.sort((a, b) => a.unit_number - b.unit_number)
    );
  });

  return result;
};


export const generateZip = async (images, fileName) => {
  const zip = JSZip();

  if (images.length) {
    for (const [index, image] of images.entries()) {
      const fileBlob = await fetch(`${image}?cacheblock=true`).then((response) => response.blob()).catch((error) => console.error('error', error));
      const fileExtension = image.split('.').pop();

      if (fileExtension === 'pdf' || fileBlob?.type === 'application/pdf') {
        zip.file(`${fileName}/${index}.pdf`, fileBlob);
      } else if (fileBlob?.type?.includes("video")) {
        zip.file(`${fileName}/${index}.mov`, fileBlob, { binary: true });
      } else {
        zip.file(`${fileName}/${index}.png`, fileBlob, { binary: true });
      }
    }
    download(zip, fileName);
  }
};

function updateObjectWithLogs(originalObj, statusLog, confirm_info, review_info, initialItem, is_counted_log) {
  const newObj = { ...originalObj };

  if (typeof statusLog !== 'undefined') {
    if (typeof newObj['status_log'] === 'undefined') {
      newObj['status_log'] = [];
    }

    const isItemStatusChanged = initialItem?.order_status != newObj?.order_status

    if (!isItemStatusChanged){
      newObj['status_log'] = initialItem?.status_log;
    }else {
    if (initialItem?.['status_log']?.length > 0) {
      newObj['status_log'] = [...initialItem['status_log'], statusLog];
    } else {
      newObj['status_log'] = [statusLog];
    }
    }
  }

  if (typeof confirm_info !== 'undefined') {
    if (typeof newObj['confirm_info'] === 'undefined') {
      newObj['confirm_info'] = [];
    }
    newObj['confirm_info'] = confirm_info;
  }

  if (typeof review_info !== 'undefined') {
    if (typeof newObj['review_info'] === 'undefined') {
      newObj['review_info'] = [];
    }
    newObj['review_info'] = review_info;
  }

  if (typeof is_counted_log !== 'undefined') {
    if (typeof newObj['is_counted_log'] === 'undefined') {
      newObj['is_counted_log'] = [];
    }
    newObj['is_counted_log'] = is_counted_log;
  }

  return newObj;
}


const defaultImage = 'https://cdn2.iconfinder.com/data/icons/symbol-blue-set-3/100/Untitled-1-94-512.png';

const isRoleHavePermission = (allowedRoles, role) => {
  return allowedRoles.includes(role)
}

const initStockBox = ({ warehouseBoxUnit, warehouseBoxUnitNumber, warehouseBoxId, userFullName }) => {
  const stockBoxObject = { note: '', box_id: warehouseBoxUnit + warehouseBoxUnitNumber, no_invoice: false, date: new Date(), owner: userFullName, _id: warehouseBoxId }
  return stockBoxObject
}

const separateAndCapitalize = (str, symbol = ' ') => {
  const words = str?.split(symbol);
  const capitalizedWords = words?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1));
  return capitalizedWords?.join(' ');
}

const LOGIC_CHANGE_DATES = {
  MULTIPLIER_REMOVAL_DATE :  "2024-05-10T14:00:00",
}


const calculateLoncaVendorCurrency = ({payment_at, rate_usd_tr_buying, rate_usd_buying, rate_usd_selling}) => {
  const shouldRemoveMultiplier = payment_at > LOGIC_CHANGE_DATES["MULTIPLIER_REMOVAL_DATE"]

  const lonceVendorCurrency = rate_usd_tr_buying
  const currencyMultiplier  = shouldRemoveMultiplier ? 1 : (rate_usd_buying / rate_usd_selling)
  return lonceVendorCurrency * currencyMultiplier
}

const renderLoncaVendorCurrency = (item) => {
  const loncaVendorCurrency = calculateLoncaVendorCurrency({
    payment_at: item?.payment_at, 
    rate_usd_tr_buying: item?.rate_usd_tr_buying,
    rate_usd_buying: item?.rate_usd_buying,
    rate_usd_selling: item?.rate_usd_selling
  })
  return `₺ ${loncaRound(loncaVendorCurrency)}`

}


const calculateVendorCogs = ({payment_at, cogs, rate_usd_tr_buying, rate_usd_buying, rate_usd_selling}) => {

  const loncaVendorCurrency = calculateLoncaVendorCurrency({
    payment_at,
    rate_usd_tr_buying,
    rate_usd_buying,
    rate_usd_selling
  })
  const calculatedCogs = cogs * loncaVendorCurrency

  return calculatedCogs
}

const renderCalculatedVendorCogs = ({payment_at, cogs, rate_usd_tr_buying, rate_usd_buying, rate_usd_selling}) => {
  const calculatedCogs = calculateVendorCogs({
    payment_at,
    cogs,
    rate_usd_tr_buying,
    rate_usd_buying,
    rate_usd_selling,
  })
  return `$${cogs} - ₺${loncaRound(calculatedCogs)}`
}

function isMediaListExceedSizeThreshold(fileList) {
  const MAX_MEDIA_THRESHOLD_MB = 100;

  const filesSizesMB = fileList?.map(file => file?.originFileObj?.size / (1024 * 1024))
  return filesSizesMB?.some((file) => file > MAX_MEDIA_THRESHOLD_MB)

}

const downloadCartItemImages = async (arr) => {
  if (!arr.length) return;

  const zip = new JSZip();
  const mediaPromises = [];

  arr.forEach((element) => {
    if (element.product_images) {
      mediaPromises.push(
        ...element.product_images.map(async (image, index) => {
          try {
            const timestamp = new Date().getTime();
            const link = `${image}?timestamp=${timestamp}`

            const response = await fetch(link);
            if (!response.ok) {
              console.error(`Failed to fetch image ${image}`);
              return null;
            }

            const imageBlob = await response.blob();
            return { blob: imageBlob, element, index, type: 'image' };
          } catch (error) {
            console.error('Error fetching image:', error);
            return null;
          }
        })
      );
    }

    if (element.product_videos) {
      mediaPromises.push(
        ...element.product_videos.map(async (video, index) => {
          try {
            const timestamp = new Date().getTime();
            const link = `${video}?timestamp=${timestamp}`;

            const response = await fetch(link);
            if (!response.ok) {
              console.error(`Failed to fetch video ${video}`);
              return null;
            }

            const videoBlob = await response.blob();
            return { blob: videoBlob, element, index, type: 'video' };
          } catch (error) {
            console.error('Error fetching video:', error);
            return null;
          }
        })
      );
    }
  });

  const mediaResults = await Promise.all(mediaPromises);

  mediaResults
    .filter(result => result)
    .forEach(({ blob, element, index, type }) => {
      const folderName = `media/${element?.names?.en}`;
      const fileName = `${index}.${type === 'image' ? 'png' : 'mp4'}`;
      zip.file(`${folderName}/${fileName}`, blob, {
        binary: true,
      });
    });

  download(zip);
};
  
const checkIsAllowedToSeeForProductPage = (adminRole) => {
  return adminRole !== "Warehouse";
}

const uploadVendorBannerImage = async (vendorName, fileList, fileType="banner") => {
  const linkList = [];
  for(let index = 0; index < fileList.length; index += 1){
    const fileUrl = `vendor/${vendorName.replace(" ", "_")}/${fileType.toLocaleLowerCase()}/${new Date().getTime()}.webp`
    await fetchUploadUrl(fileUrl, "image/webp")
        .then(async (response) => {
            await fetch(response.data, {
                method: "PUT",
                body: fileList[index].originFileObj,
            })

            const imageUrl = response.data.split('?')[0];
            linkList.push(imageUrl);
        })
        .catch((err) => {
            console.log(err);
            notification['error']({
                message: `Error uploading image #${index}`
            });
        });
  }
  return linkList;

}

const stockOutRatioExceeded = (cartItem) => {
  const statusCounts = {
      "Confirmed": 0,
      "Received": 0,
      "Reviewed": 0,
      "StockOut": 0,
      "StockIn": 0,
      "Wrong / Missing Items": 0,
      "totalStatusCount": 0,
  };

  cartItem?.forEach(item => {
      const status = item.order_status;
      if (statusCounts.hasOwnProperty(status)) {
          statusCounts[status]++;
          statusCounts["totalStatusCount"]++;
      }
  });

  const exceedStockOutRatio = (statusCounts["StockOut"] / statusCounts["totalStatusCount"]) >= ALLOW_AUTO_REFUND_STOCK_OUT_RATIO;

  return exceedStockOutRatio;
};

const sortVendors = (vendors) => {
    return vendors?.toSorted((a, b) => a?.vendor_name?.localeCompare(b?.vendor_name));
}

const getCouponPercentage = (order) => {
    let couponPercentage = 0.0;
    const discountType = order?.coupon?.discount_type;
    const rateUSDSelling = order?.rate_usd_selling;
    const discountAmount = order?.coupon?.discount_amount;

    if (discountType === "Percentage") {
        couponPercentage = discountAmount
    } else if(discountType === "Absolute") {
      const grossVolumeWithoutStockouts = loncaRound(order?.net_volume || 0 )+ (order?.returns || 0) + (order?.vat_discount || 0)
      if(!isNaN(grossVolumeWithoutStockouts) && !isNaN(discountAmount) && !isNaN(rateUSDSelling)) {
          const absoluteCouponValue = discountAmount / rateUSDSelling;
          couponPercentage =  absoluteCouponValue / grossVolumeWithoutStockouts;
        }
    }
    return couponPercentage;
}

const capitalizeWord = (word) => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1);
}

export {
  loncaRound,
  getDate,
  getTime,
  additionalServicesFilter,
  getChangedValues,
  defaultImage,
  convertToHandle,
  productToOrderItem,
  productToCartItem,
  calculateItemPrice,
  calculateItemPriceWithCogs,
  calculateItemPriceWithVAT,
  changeCartItem,
  checkOrderStatus,
  getBase64,
  uploadMedias,
  uploadChartImage,
  loncaFormatDate,
  getImageUrl,
  isRoleHavePermission,
  decodeTurkishCharacters,
  initStockBox,
  separateAndCapitalize,
  isMediaListExceedSizeThreshold,
  calculateVendorCogs,
  renderCalculatedVendorCogs,
  renderLoncaVendorCurrency,
  downloadCartItemImages,
  checkIsAllowedToSeeForProductPage,
  uploadVendorBannerImage,
  calculateFavoriteProductPrice,
  stockOutRatioExceeded,
  sortVendors,
  getCouponPercentage,
  capitalizeWord
};
