import React, { useState } from 'react';
import { Button, Upload, Modal, Image, notification } from 'antd';
import { PlusOutlined, CheckCircleOutlined, DeleteOutlined, EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { ResponsiveImage } from "..";
import { generateZip, getBase64 } from "../../helpers"
import { uploadMedias } from '../../helpers';
import './ImageBox.css';

export const ImageUploader = ({
  fileList,
  product,
  handlePreview,
  setFileList,
  getBase64,
  previewVisible,
  previewTitle,
  previewImage,
  previewType,
  setPreviewVisible,
  isEditable = true,
  nonEditableTitle
}) => {

    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleImageDownload = async () => {
        try {
            setDownloadLoading(true);
            generateZip([...product?.images, ...(product?.videos || [])], product?.names?.en)
                .then(() => {
                    notification["success"]({
                        message: "Media downloaded successfully",
                    });
                    setDownloadLoading(false);
                })
                .catch(err => {
                    setDownloadLoading(false);
                })
        } catch (err) {
            console.log(err)
        }
    }
    return (
        <div className="">
            {isEditable ?
                <div style={{"position": "relative"}}>
                    <Button type="primary" loading={downloadLoading} className="download-button" onClick={() => handleImageDownload()}>Download Images</Button>
                    <p>Add Photos for Product</p>
                    <p>To select main image or delete an image, hover the cursor over the product and use buttons</p>
                </div> :
                <div style={{"position": "relative"}}>
                    <Button type="primary" loading={downloadLoading} onClick={() => handleImageDownload()}>Download Images</Button>
                    <p>{nonEditableTitle}</p>
                </div>
            }
            
            <div style={{marginTop: "10px"}}>
                <Upload
                    listType="picture-card"
                    multiple={true}
                    fileList={fileList}
                    onPreview={handlePreview}
                    itemRender={(originNode, file, fileList, { remove }) => {
                        return (
                            <div className={`single-image ${file?.is_main ? "main-img" : ""}`}>
                                {file?.existBeforeUpdate ?
                                    <ResponsiveImage width={86} height={86} src={file.url} alt={file.uid} />
                                    : <img width={86} height={86} src={file.url} alt={file.uid} />
                                }

                                <div className="shadow-img" onClick={(e) => {handlePreview(file)}}>
                                    {isEditable && 
                                        <CheckCircleOutlined className="main-selector" 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                const newFileList = fileList.map(f => {
                                                    return {
                                                    ...f,
                                                    is_main: f.uid === file.uid
                                                    }
                                                }).sort((file1, file2) => file1?.is_main ? -1 : file2?.is_main ? 1 : 0 )
                                                setFileList(newFileList);
                                            }} 
                                        />
                                    }
                                    {isEditable &&
                                        <DeleteOutlined className="trash-selector" 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                remove();
                                            }} 
                                        />
                                    }
                                </div>
                            </div>
                        )
                    }}
                    onChange={async ({ file: updatedFile, fileList: newFileList }) => {
                        if (fileList.length < newFileList.length) {
                            const url = await getBase64(updatedFile.originFileObj);
                            setFileList((currentList) => [...currentList, { ...updatedFile, status: "done", url, is_main: currentList.length === 0 }]);
                        } else if (fileList.length > newFileList.length) {
                            let updatedList = newFileList.filter(f => f.status !== "removed");

                            if (updatedList?.length > 0 && updatedFile?.is_main) {
                                updatedList = [{ ...updatedList[0], is_main: true }, ...updatedList.slice(1)]
                            }

                            setFileList(updatedList);
                        }
                    }}
                >
                    {isEditable && <div>
                        <PlusOutlined />
                        <div
                            className="plus-outlined-margin"
                        >
                            Upload
                        </div>
                    </div>}
                </Upload>
            </div>
            

            <Modal visible={previewVisible && previewType === 'image'} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img
                    alt="example"
                    className="input-width"
                    src={previewImage}
                />
            </Modal>

        </div>
    );
};

export const ImageUploaderForRefund = ({
    fileList,
    refund,
    handlePreview,
    setFileList,
    getBase64,
    previewVisible,
    previewTitle,
    previewFile,
    setPreviewVisible,
    setHasChanged,
    setIsDirty,
    setRefund
}) => {

    const product = refund?.product;
    const [downloadLoading, setDownloadLoading] = useState(false);

    const handleImageDownload = () => {
        const customerName = refund?.order?.customer_name
        const unique_order_id = refund?.order?.unique_order_id

        try {
            setDownloadLoading(true);
            generateZip(fileList.map(image => image?.url), customerName + " " + unique_order_id)
                .then(() => {
                    notification["success"]({
                        message: "Images are downloaded successfully",
                    });
                    setDownloadLoading(false);
                })
                .catch(err => {
                    setDownloadLoading(false);
                })
        }
        catch(error) {
            notification["error"]({
                message: error,
            });
            console.error(error);
        };
    };

    const uploadImage = async(fileList) => {

        if (fileList?.length > 0) {

            const newFiles = fileList?.filter(i => !i?.existBeforeUpdate);
            const oldFiles = fileList?.filter(i => i?.existBeforeUpdate);

            let imageLinks = [];
            if (newFiles?.length) {
                const skuForUplaod = newFiles[0]?.sku + "-admin";
                const folderName = `${refund?.order?._id}-${refund?.order?.customer_name?.split(' ')?.[0]}`;
                imageLinks = await uploadMedias(
                    folderName,
                    newFiles,
                    skuForUplaod,
                    oldFiles?.length,
                    "refund"
                );

                if (imageLinks.length === newFiles?.length) {

                    notification['success']({
                        message: 'All images loaded to AWS successfully',
                    });
                    } else {
    
                    notification['error']({
                        message: 'All images couldn\'t loaded to AWS successfully',
                    });
                };
            }
            
            imageLinks = [...(oldFiles?.map(image => image?.url) || []), ...(imageLinks || [])];

            setRefund({
                ...refund,
                images: imageLinks
            })
        }
    }

    return (
        <div className="refund-form-bottom">
            <Button loading={downloadLoading} type="primary" className="download-button" onClick={() => handleImageDownload()}>Download Images</Button>
            <p>Return Photos</p>

            <Upload
                listType="picture-card"
                multiple={true}
                fileList={fileList}
                onPreview={handlePreview}
                itemRender={(_, file, __, { remove }) => {
                    return (
                        <div className={`single-image`}>

                           { !file?.imgError && file?.existBeforeUpdate 
                                ? 
                                    <ResponsiveImage 
                                        width={86} 
                                        height={86} 
                                        src={file.url || file} 
                                        alt={file.uid} 
                                        onError={(err) => {
                                            const index = fileList?.findIndex(f => f?.url === file?.url);
                                            setFileList([
                                                ...fileList?.slice(0, index),
                                                { ...file, imgError: true },
                                                ...fileList?.slice(index + 1)
                                            ])
                                        }}
                                    />
                                :
                                    <img width={86} height={86} src={file.url} alt={file.uid} />
                            }

                            { file?.imgError && 
                                <video width={86} height={86}  controls>
                                    <source src={file?.url} />
                                </video>
                            }

                            <div className="shadow-img" >

                                <DeleteOutlined className="trash-selector" onClick={() => {
                                    remove();
                                    setHasChanged(c => !c)
                                    setIsDirty(true)
                                }} />

                                <EyeOutlined className="main-selector" onClick={() => {
                                    handlePreview(file)
                                }} />
                            </div>
                        </div>
                    )
                }}
                onChange={async ({ file: updatedFile, fileList: newFileList }) => {
                    if (fileList.length < newFileList.length) {
                        const url = await getBase64(updatedFile.originFileObj)
                        setFileList(currentList => [...currentList, { ...updatedFile, status: "done", url, sku: product?.handle, existBeforeUpdate: false }]);
                        await uploadImage([...newFileList.slice(0, newFileList.length - 1), { ...newFileList[newFileList.length - 1], status: "done", url, sku: product?.handle, existBeforeUpdate: false }]);
                    } else if (fileList.length > newFileList.length) {
                        let updatedList = newFileList.filter(f => f.status !== "removed");

                        if (updatedList?.length > 0 && updatedFile?.is_main) {
                            updatedList = [{ ...updatedList[0], is_main: true }, ...updatedList.slice(1)]
                        }
                        setFileList(updatedList);
                    }
                    setHasChanged(c => !c)
                    setIsDirty(true)
                }}
            >
                <div>
                    <PlusOutlined />
                    <div
                        className="plus-outlined-margin"
                    >
                        Upload
                    </div>
                </div>
            </Upload>

            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                { !previewFile?.imgError && 
                    <img 
                        className="input-width" 
                        src={previewFile.url || previewFile} 
                        alt={previewFile.uid} 
                        onError={() => {
                            const index = fileList?.findIndex(f => f?.url === previewFile?.url);
                            setFileList([
                                ...fileList?.slice(0, index),
                                { ...previewFile, imgError: true },
                                ...fileList?.slice(index + 1)
                            ])
                        }}
                    />
                }

                { previewFile?.imgError && 
                    <video className="input-width" controls>
                        <source src={previewFile?.url} />
                    </video>
                }
            </Modal>

        </div>
    );
};

export const ImageUploaderForVendorBanner = ({
    fileList,
    setFileList,
    handlePreview,
    previewVisible,
    previewTitle,
    previewImage,
    setPreviewVisible
}) => {
    return (
        <>
            <div style={{"position": "relative", marginBottom: "10px"}}>
                <h3>Add Banners for Vendor</h3>
                <span>To select main image or delete an image, hover the cursor over the product and use buttons</span>
            </div> 
            
            <Upload 
                listType='picture-card' 
                fileList={fileList}
                onPreview={handlePreview}
                itemRender={(originNode, file, fileList, { remove }) => {
                    return (
                        <div className={`vendor-banner-single-image ${file?.is_main ? "vendor-banner-main-image" : ""}`}>
                            {file?.existBeforeUpdate ?
                                <ResponsiveImage width={86} height={86} src={file.url} alt={file.uid} />
                                : <img width={86} height={86} src={file.url} alt={file.uid} />
                            }

                            <div className='vendor-banner-shadow-image' onClick={(e) => handlePreview(file)}>
                                <CheckCircleOutlined 
                                    className='vendor-banner-main-selector'
                                    onClick={() => {
                                        const newFileList = fileList.map(f => {
                                            return {
                                                ...f,
                                                is_main: f.uid === file.uid
                                            }
                                        }).sort((file1, file2) => file1?.is_main ? -1 : file2?.is_main ? 1 : 0 )
                                        setFileList(newFileList);
                                    }} 
                                />
                                <DeleteOutlined 
                                    className='vendor-banner-trash-selector'
                                    onClick={() => {
                                        remove();
                                    }}
                                />
                            </div>
                        </div>
                    )
                }}
                onChange={async ({file: updatedFile, fileList: newFileList}) => {
                    if ((fileList?.length || 0) < (newFileList?.length || 0)) {
                        const url = await getBase64(updatedFile.originFileObj);
                        setFileList([...newFileList?.slice(0, newFileList?.length - 1), { ...newFileList[newFileList?.length - 1], url: url, status: "done", is_main: fileList?.length === 0}]);
                    } else if ((fileList?.length || 0) > (newFileList?.length || 0)) {
                        let updatedList = newFileList?.filter(f => f.status !== "removed");
                        if (updatedList?.length > 0 && updatedFile?.is_main) {
                            updatedList = [{ ...updatedList[0], is_main: true }, ...updatedList.slice(1)]
                        }

                        setFileList(updatedList);
                    }
                    
                }}
            >
                <div>
                    <UploadOutlined />
                    <div>
                        Upload
                    </div>
                </div>
            </Upload>

            <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={() => setPreviewVisible(false)}>
                <img
                    alt="example"
                    className="input-width"
                    src={previewImage}
                />
            </Modal>
        </>
    )
}
