import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button, Input, Modal, Select, Spin, notification, Radio } from "antd";
import { LeftCircleOutlined, RightCircleOutlined, CloseOutlined } from "@ant-design/icons";

import { fetchProductAggregate, updateTagProducts } from "../../redux/productSlice";
import { separateAndCapitalize } from "../../helpers";
import { fetchCategorizedTags } from "../../redux/tagSlice";
import { Pagination, ResponsiveImage } from '../../components';

import "./TagProducts.scss";

const TagProducts = () => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const { filterTypes } = useSelector((state) => state.filterType);
    const { status: tagStatus, tags, categorizedTags, parentSubtypes, allowedFilterTypeListOfCategorizedTags } = useSelector((state) => state.tags);
    const { vendors } = useSelector((state) => state.vendors);
    const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);
    const sortedProductTypesForFilter = productTypes?.slice().sort((a, b) => a.names?.en?.localeCompare(b.names?.en))?.map(pt => {
        return { label: pt?.names?.en, value: pt?._id }
    });

    sortedProductTypesForFilter?.unshift({label: "All", value: "all"});

    const sortedVendorsForFilter = vendors?.slice()?.sort((a,b) => a?.name?.localeCompare(b?.name))?.map(v => {
        return { label: v?.name, value: v?._id }
    });

    sortedVendorsForFilter?.unshift({label: "All", value: "all"});

    const sortedFilterTypesForFilter = filterTypes?.slice()?.sort((a,b) => a?.name?.localeCompare(b?.name))?.map(f => {
        return { label: f?.name, value: f?.type }
    });

    sortedFilterTypesForFilter.unshift({label: "All", value: "all"})

    const [pagination, setPagination] = useState({ page: 1, limit: 100 });
    const [tagProducts, setTagProducts] = useState([]);
    const [changedTagProducts, setChangedTagProducts] = useState([]);
    const [initialProducts, setInitialProducts] = useState([]);
    const [productImageIndices, setProductImageIndices] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [filters, setFilters] = useState({ missing_tags: "", fabric: "" });
    const [totalCount, setTotalCount] = useState(0);
    const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);
    const [conflictedProducts, setConflicftedProducts] = useState({});
    const [selectedProductTypes, setSelectedProductTypes] = useState([]);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);

    const onProductTypesChange = (checkedValues) => {
        if(checkedValues?.includes("all")) {
            const allProductTypes = sortedProductTypesForFilter?.map(option => option?.value);
            setSelectedProductTypes(allProductTypes);
            setFilters({
                ...filters,
                product_type: allProductTypes?.filter(pt => pt !== "all")
            });
        } else if(selectedProductTypes?.includes("all")) {
            setSelectedProductTypes([]);
            setFilters({
                ...filters,
                product_type: []
            });
        } else {
            setSelectedProductTypes(checkedValues);
            setFilters({
                ...filters,
                product_type: checkedValues
            });
        }
    };

    const onVendorsChange = (checkedValues) => {
        if(checkedValues?.includes("all")) {
            const allVendors = sortedVendorsForFilter?.map(option => option?.value);
            setSelectedVendors(allVendors);
            setFilters({
                ...filters,
                vendor: allVendors?.filter(v => v !== "all")
            });
        } else if(selectedVendors?.includes("all")) {
            setSelectedVendors([]);
            setFilters({
                ...filters,
                vendor: []
            });
        } else {
            setSelectedVendors(checkedValues);
            setFilters({
                ...filters,
                vendor: checkedValues
            });
        }
    };

    const onHasMissingTagByFilterTypeChange = (checkedValues) => {
        if(checkedValues?.includes("all")) {
            const allFilterTypes = sortedFilterTypesForFilter?.map(option => option?.value);
            setSelectedFilterTypes(allFilterTypes);
            setFilters({
                ...filters,
                has_missing_type_by_filter_type: allFilterTypes?.filter(f => f !== "all")
            });
        } else if(selectedFilterTypes?.includes("all")) {
            setSelectedFilterTypes([]);
            setFilters({
                ...filters,
                has_missing_type_by_filter_type: []
            });
        } else {
            setSelectedFilterTypes(checkedValues);
            setFilters({
                ...filters,
                has_missing_type_by_filter_type: checkedValues
            });
        }
    };

    useEffect(() => {
        if (tagStatus.fetchCategorizedTags === "idle") dispatch(fetchCategorizedTags());
    }, [tagStatus]);

    useEffect(() => {
        setFetchLoading(true);

        const source = axios.CancelToken.source();
        let isCancelled = false;

        fetchProductAggregate({
            filter: {
                pagination,
                searchText,
                filters
            },
            mode: "tag_products",
            cancelToken: source.token
        })
            .then(res => {
                const count = res?.data?.[0]?.totalCount?.[0]?.count;
                let newTagProducts = res?.data?.[0]?.data;

                setTagProducts(newTagProducts);
                setInitialProducts(newTagProducts);
                setTotalCount(count);

                let newProductImages = {};
                newTagProducts?.forEach(p => {
                    newProductImages[p?._id?.toString()] = 0;
                });

                setProductImageIndices(newProductImages);
                if (!isCancelled) {
                    setFetchLoading(false);
                }
            })
            .catch(() => {
                if (!isCancelled) {
                    setFetchLoading(false);
                }
            })

        return () => {
            isCancelled = true;
            source.cancel('Operation canceled by the user.');
        }
    }, [pagination, searchText, filters, refresh]);

    const handleImageNavigation = (tagProduct, increment) => {
        const currentIndex = productImageIndices?.[tagProduct?._id?.toString()];

        if (increment === 1) {
            let newIndex = currentIndex + 1;
            if (currentIndex === tagProduct?.images?.length - 1) newIndex = 0;
            setProductImageIndices(indices => ({ ...indices, [tagProduct?._id?.toString()]: newIndex }));
            return;
        } else if (increment === -1) {
            let newIndex = currentIndex - 1;
            if (currentIndex === 0) newIndex = tagProduct?.images?.length - 1;
            setProductImageIndices(indices => ({ ...indices, [tagProduct?._id?.toString()]: newIndex }));
            return;
        }
    };

    const handleTagChange = (product, tagId) => {
        const addedTag = tags.find((a) => a?._id?.toString() === tagId?.toString());

        if (!product.tags.find((tag) => tag?._id?.toString() === tagId?.toString())) {
            const productIndex = tagProducts?.findIndex(tp => tp?._id?.toString() === product?._id?.toString());
            const newProduct = { ...product, tags: [ ...product?.tags, addedTag ] };

            setTagProducts([
                ...tagProducts?.slice(0, productIndex), 
                newProduct,
                ...tagProducts?.slice(productIndex + 1), 
            ]);

            setChangedTagProducts([
                ...changedTagProducts?.filter(cp => cp?._id?.toString() !== product?._id?.toString()),
                newProduct
            ]);
        }
    };

    const handleDelete = (product, tagId) => {
        if (product?.tags?.find((tag) => tag?._id?.toString() === tagId?.toString())) {
            const productIndex = tagProducts?.findIndex(tp => tp?._id?.toString() === product?._id?.toString());
            const newProduct = { ...product, tags: product?.tags?.filter(t => t?._id?.toString() !== tagId) };

            setTagProducts([
                ...tagProducts?.slice(0, productIndex), 
                newProduct,
                ...tagProducts?.slice(productIndex + 1), 
            ]);

            setChangedTagProducts([
                ...changedTagProducts?.filter(cp => cp?._id?.toString() !== product?._id?.toString()),
                newProduct
            ]);
        }
    };

    const handleProductSubtypes = (product, checkedValues) => {
        const productIndex = tagProducts?.findIndex(tp => tp?._id?.toString() === product?._id?.toString());
        const changedIndex = changedTagProducts?.findIndex(tp => tp?._id?.toString() === product?._id?.toString());
        const newSubtypes = productTypes?.filter(pt => checkedValues?.includes(pt?._id?.toString()));
        const newProduct = { ...product, sub_product_types_updated: true, sub_product_types: newSubtypes };

        setTagProducts([
            ...tagProducts?.slice(0, productIndex), 
            newProduct,
            ...tagProducts?.slice(productIndex + 1), 
        ]);

        setChangedTagProducts(cp => [
            ...cp?.slice(0, changedIndex), 
            newProduct,
            ...cp?.slice(changedIndex + 1), 
        ]);
    }

    const handleRefresh = () => {
        setTagProducts([]);
        setChangedTagProducts([]);
        setInitialProducts([]);
        setRefresh(r => !r);
    }

    const handleSubmit = () => {
        let checkConflictOnly = true;
        setSaveLoading(true);
        updateTagProducts({ changedProducts: changedTagProducts, initialProducts, checkConflictOnly })
            .then((response) => {
                if(response?.data?.isConflicted) {
                    setShowOverwriteWarning(true);
                    setConflicftedProducts(response?.data?.conflictedTagsForProducts);
                    setSaveLoading(false);
                } else {
                    setSaveLoading(false);
                    handleRefresh();
                    notification['success']({
                        message: 'Changes saved successfully',
                    });
                }
            })
            .catch(() => {
                setSaveLoading(false);

                notification['error']({
                    message: 'Changes couldn\'t saved successfully',
                });
            });
    }

    
    const handleOverwrite = (isOverwrite) => {
        setShowOverwriteWarning(false);
        let newChangedTagProducts = [];
        let checkConflictOnly = false;
        
        if(isOverwrite) {
            newChangedTagProducts =  changedTagProducts.map((previousChangedTagProduct) => {
                if(previousChangedTagProduct?._id in conflictedProducts) {
                    return { ...previousChangedTagProduct, tags: [ ...previousChangedTagProduct?.tags ] }
                } else {
                    return { ...previousChangedTagProduct };
                }
            });
        } else {
            newChangedTagProducts = changedTagProducts.map((previousChangedTagProduct) => {
                if(previousChangedTagProduct?._id in conflictedProducts) {
                    return { ...previousChangedTagProduct, "tags": [ ...previousChangedTagProduct?.tags, ...conflictedProducts[`${previousChangedTagProduct?._id}`]] }
                } else {
                    return { ...previousChangedTagProduct };
                }
            });
        }
        
        updateTagProducts({ changedProducts: newChangedTagProducts, initialProducts, checkConflictOnly })
            .then(() => {
                setSaveLoading(false);
                handleRefresh();
                notification['success']({
                    message: 'Changes saved successfully',
                });
            })
            .catch(() => {
                setSaveLoading(false);
                
                notification['error']({
                    message: 'Changes couldn\'t saved successfully',
                });
            });
    }

    const sortBasedOnType = (a, b) => a?.type?.localeCompare(b?.type);

    const hasTag = (productId, tagId) => {
        const tag = tagProducts?.filter(p => p?._id === productId)?.filter(p => {
            const currentTags = p?.tags;
            return currentTags?.some(t => t?._id === tagId)
        });
        return tag?.length > 0;
    }

    const isTagTypeAdded = (type, tags) => {
        const isAdded = tags?.some(t => t?.type === type);
        return isAdded;
ü    }

    return (
        <div className="tag-products-container">
            <Modal
                title={t('tags.overwrite_warning_title')}
                visible={showOverwriteWarning}
                width={800}
                onCancel={() => setShowOverwriteWarning(false)}
                footer={[
                    <Button
                        key="ok" 
                        type="secondary"
                        onClick={() => handleOverwrite(true) }
                    >
                    {t('tags.save_and_overwrite')}
                    </Button>,
                    <Button 
                        key="cancel" 
                        type="primary"
                        onClick={() => handleOverwrite(false)}
                    >
                    {t('tags.save_not_overwrite')}
                    </Button>
                ]}
            >
                <p>{t('tags.overwrite_warning')}</p>
            </Modal>
            <div className="tag-products-top-bar">
                <h2 className="tag-products-header">
                    Tag Products {"  "}
                    { fetchLoading && <Spin /> }
                   
                </h2>

                <span>Total Count: {totalCount}</span>

                <div className='tag-products-top-btns'>
                    <Button className='tag-products-save-btn' loading={saveLoading} type='primary' onClick={handleSubmit}>{t('buttons.Save')}</Button>
                    <Button 
                        className='tag-products-discard-btn' 
                        type='primary' 
                        onClick={() => {
                            if (window.confirm('Do you want do discard all changes?')) handleRefresh();
                        }}
                    >
                        {t(`buttons.Discard Changes`)}
                    </Button>
                </div>
            </div>

            <div className="tag-products-filter-labels">
                <span className="tag-products-search-bar" >Search</span>

                <span className="tag-products-filter">Status</span>

                <span className="tag-products-filter">Has Fabric</span>

                <span className="tag-products-filter">Has Missing Tag</span>

            </div>

            <div className="tag-products-filters">
                <Input className="tag-products-search-bar" placeholder={t('placeholders.Search')} onChange={(e) => setSearchText(e.target.value)} />

                <Select
                    className="tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, status: value }))}
                    defaultValue=""
                >
                    <Option value="">All Status</Option>
                    <Option value="Active">Active</Option>
                    <Option value="Passive">Passive</Option>
                    <Option value="Draft">Draft</Option>
                </Select>

                <Select
                    className="tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, fabric: value }))}
                    defaultValue=""
                    placeholder="Has fabric"
                >
                    <Option value="">All</Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>

                <Select
                    className="tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, missing_tags: value }))}
                    defaultValue=""
                    placeholder="Has missing tags"
                >
                    <Option value="">All</Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>
            </div>

            <div className="tag-products-filter-labels"> 
                <span className="wider-tag-products-filter">Tags</span>
                <span className="wider-tag-products-filter">Exclude Tags</span>
                <span className="wider-tag-products-filter">Product Types</span>
                <span className="wider-tag-products-filter">Vendors</span>
            </div>

            <div className="tag-products-filters">
                <Select
                    className="wider-tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, tags: value }))}
                    defaultValue={[]}
                    showSearch 
                    optionFilterProp="children"
                    mode="multiple"
                    placeholder="Select Tag(s)"
                    showArrow
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    {tags?.slice().sort((a, b) => a?.names?.en.localeCompare(b?.names?.en)).map((tag, index) => (
                        <Option key={'tag-select' + index} value={tag?._id}>
                            {tag?.names?.en}
                        </Option>
                    ))}
                </Select>

                <Select
                    className="wider-tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, exclude_tags: value }))}
                    defaultValue={[]}
                    showSearch 
                    optionFilterProp="children"
                    mode="multiple"
                    placeholder="Select Tag(s) to Exclude"
                    showArrow
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                    }
                >
                    {tags?.slice().sort((a, b) => a?.names?.en.localeCompare(b?.names?.en)).map((tag, index) => (
                        <Option key={'tag-select' + index} value={tag?._id}>
                            {tag?.names?.en}
                        </Option>
                    ))}
                </Select>

                <Select
                    className="wider-tag-products-filter"
                    mode="multiple"
                    allowClear
                    placeholder="Select Product Type(s)"
                    value={selectedProductTypes}
                    onChange={onProductTypesChange}
                    options={sortedProductTypesForFilter}
                    maxTagCount="responsive"
                    showArrow
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                />

                <Select
                    className="wider-tag-products-filter"
                    mode="multiple"
                    allowClear
                    placeholder="Select Vendor(s)"
                    value={selectedVendors}
                    onChange={onVendorsChange}
                    options={sortedVendorsForFilter}
                    maxTagCount="responsive"
                    showArrow
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                />
            </div>

            <div className="tag-products-filter-labels"> 
                <span className="wider-tag-products-filter">Has Missing Tag By Filter Type</span>
                <span className="tag-products-filter">Has Been Processed</span>
                <span className="tag-products-filter">Has Product Subtype</span>
                <span className="tag-products-filter">Product Subtypes</span>
            </div>

            <div className="tag-products-filters">
                <Select
                    className="wider-tag-products-filter"
                    mode="multiple"
                    allowClear
                    placeholder="Select Missing Tag(s) By Filter Type"
                    value={selectedFilterTypes}
                    onChange={onHasMissingTagByFilterTypeChange}
                    options={sortedFilterTypesForFilter}
                    maxTagCount="responsive"
                    showArrow
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                />

                <Select
                    className="tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, has_been_processed: value }))}
                    defaultValue=""
                    placeholder="Has been processed"
                >
                    <Option value="">All</Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>

                <Select
                    className="tag-products-filter"
                    onChange={(value) => setFilters(filters => ({ ...filters, has_product_subtype: value }))}
                    defaultValue=""
                >
                    <Option value="">All</Option>
                    <Option value={true}>Yes</Option>
                    <Option value={false}>No</Option>
                </Select>

                <Select
                    className="wider-tag-products-filter"
                    allowClear
                    placeholder="Select Product Sub-Type"
                    onChange={checkedValue => {
                        if (checkedValue === "all") {
                            const newFilters = { ...filters };
                            delete newFilters.sub_product_types;
                            setFilters(newFilters);
                            return;
                        }

                        setFilters({
                            ...filters,
                            sub_product_types: checkedValue
                        });
                    }}
                    options={[
                        { label: "All", value: "all" },
                        { label: "Pants", value: productTypes?.find(pt => pt?.handle === "pants")?._id },
                        { label: "Skirt", value: productTypes?.find(pt => pt?.handle === "skirt")?._id },
                        { label: "Shorts", value: productTypes?.find(pt => pt?.handle === "shorts")?._id }
                    ]}
                    maxTagCount="responsive"
                    showArrow
                    showSearch
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                />
            </div>


            <div className="tag-products-items">

                { Object.keys(categorizedTags)?.length > 0 && tagProducts?.map(tagProduct => {

                    let tagKeyArray = [];
                    const relatedParentSubtypes = parentSubtypes?.[tagProduct?.product_type?.handle];
                    const subtype = tagProduct?.sub_product_types?.[0]?.handle;
                    
                    if (relatedParentSubtypes?.includes(subtype)) {
                        tagKeyArray = allowedFilterTypeListOfCategorizedTags?.[`${tagProduct?.product_type?.handle}-${subtype}`];
                    } else {
                        tagKeyArray = Object.keys(categorizedTags?.[tagProduct?.product_type?.handle] || {});
                    }

                    return (
                        <div className={`tag-product-item ${ changedTagProducts?.find(cp => cp?._id?.toString() === tagProduct?._id?.toString()) ? "changed-tag-product-item" : "" }`}>
                            <div className="tag-product-item-image-container">
                                <LeftCircleOutlined className="tag-product-item-image-left-icon" onClick={() => handleImageNavigation(tagProduct, -1)}/>
    
                                <ResponsiveImage className="tag-product-item-image" height={400} src={tagProduct?.images?.[productImageIndices?.[tagProduct?._id?.toString()]]} alt="tag-product" />
    
                                <RightCircleOutlined className="tag-product-item-image-right-icon" onClick={() => handleImageNavigation(tagProduct, 1)}/>
    
                                <div className="tag-product-image-navigation-indicators">
                                    { tagProduct?.images?.map((_, index) => (
                                        <div className={`tag-product-image-navigation-indicator ${index === productImageIndices?.[tagProduct?._id?.toString()] ? "tag-product-image-navigation-active-indicator" : ""}`} />
                                    )) }
                                </div>
                            </div>
    
                            <div className="tag-product-item-info">
                                <div className="tag-product-item-general-info">
                                    <div className="tag-product-item-general-info-row">
                                        <span className="tag-product-item-general-info-row-name-element" >Name</span>
                                        <span className="tag-product-item-general-info-row-element" >Vendor</span>
                                        <span className="tag-product-item-general-info-row-element" >Product Type</span>
                                        <span className="tag-product-item-general-info-row-element" >Status</span>
                                        <span className="tag-product-item-general-info-row-element" >Fabric</span>
                                    </div>
                                    <div className="tag-product-item-general-info-row">
                                        <Input className="tag-product-item-general-info-row-name-element" disabled value={tagProduct?.names?.en} />
                                        <Input className="tag-product-item-general-info-row-element" disabled value={tagProduct?.vendor?.name} />
                                        <Input className="tag-product-item-general-info-row-element" disabled value={tagProduct?.product_type?.name } />
                                        <Input className="tag-product-item-general-info-row-element" disabled value={tagProduct?.status} />
                                        <Input className="tag-product-item-general-info-row-element" disabled value={tagProduct?.description_details?.en?.fabric || "-"} />
                                    </div>
                                </div>
    
                                { parentSubtypes?.[tagProduct?.product_type?.handle]?.length > 0 && 
                                    <div className="tag-product-item-general-info">
                                        <div>
                                        <span className="tag-product-item-general-info-row-element">Product Sub-Type</span>
                                        </div>
                                        <Select
                                            className={`wider-tag-products-filter ${(!tagProduct?.sub_product_types || tagProduct?.sub_product_types?.length === 0) ? "error-display" : ""}`}
                                            // mode="multiple"
                                            allowClear
                                            placeholder="Select Product Sub-Type"
                                            value={tagProduct?.sub_product_types?.map(pt => ({ label: pt?.names?.en, value: pt?._id }))}
                                            onChange={(e) => handleProductSubtypes(tagProduct, e)}
                                            options={[
                                                { label: "Pants", value: productTypes?.find(pt => pt?.handle === "pants")?._id },
                                                { label: "Skirt", value: productTypes?.find(pt => pt?.handle === "skirt")?._id },
                                                { label: "Shorts", value: productTypes?.find(pt => pt?.handle === "shorts")?._id }
                                            ]}
                                            maxTagCount="responsive"
                                            showArrow
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().includes(input.toLowerCase())
                                            }
                                        />
                                    </div>
                                }
    
                                <div className="tag-product-added-tags">
                                    {tagProduct?.tags?.filter(tag => tag?.type !== 'tag' && tag?.type !== 'price' && tag?.name !== "Discount")?.sort(sortBasedOnType).map((tag, index, array) => (
                                        <div className={`tag-product-added-tag`} key={index}>
                                            <CloseOutlined className="tag-product-added-tag-delete" onClick={() => handleDelete(tagProduct, tag?._id)} />
                                            <span className="tag-product-added-tag-type">{separateAndCapitalize(tag?.type, '_')}</span>
                                            { tag?.names?.en }
                                        </div>
                                    ))}
    
                                    {tagProduct?.tags?.filter(tag => tag?.type !== 'tag' && tag?.type !== 'price' && tag?.name !== "Discount")?.length === 0 && <div>No tag</div>}
                                </div>
    
                                <div className="tag-product-select-tags">
                                    {
                                        (tagKeyArray || [])?.map(type => (
                                            <div className="tag-product-select-tag-container">
                                                <span>{separateAndCapitalize(type, '_')} </span>
                                                <Select
                                                    key={'select-' + type}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onChange={(tagId) => handleTagChange(tagProduct, tagId)}
                                                    placeholder={`Add ${separateAndCapitalize(type, '_')}`}
                                                    className={!isTagTypeAdded(type, tagProduct?.tags) ? "error-display" : ""}
                                                >
                                                    {categorizedTags?.[tagProduct?.product_type?.handle]?.[type]
                                                        ?.slice()
                                                        .sort((a, b) => a?.names?.en?.localeCompare(b?.names?.en))
                                                        .map((tag, index) => (
                                                            <Select.Option key={'tag-select' + index} value={tag?._id} style={hasTag(tagProduct?._id, tag?._id) ? {opacity: "0.4"} : {}} disabled={hasTag(tagProduct?._id, tag?._id)}>
                                                                {tag?.names?.en}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </div>
                                        ))
                                    }
                                </div>
    
                            </div>
                        </div>
                    )
                }) }

                <Pagination filter={pagination} setFilter={setPagination} />
            </div>
        </div>
    );
};

export default TagProducts;