import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { setOrderFilter, resetOrderFilter } from '../../redux/filterSlice';
import './Orders.css';
import axios from "axios";

import { Button, Select, Form, Spin } from 'antd';
import { Order, Pagination, SearchBar, OrderTrackSearchBar} from '../../components';
import { listOrdersToState } from '../../redux/orderSlice';
import { fetchAllCompanies } from '../../redux/cargoCompanySlice';
import { ALL_ORDER_STATUSES, ADMIN_ACTIVE_ORDER_STATUSES } from '../../constants';

function Orders() {
  const { t } = useTranslation('translation');

  const dispatch = useDispatch();
  const { orderFilter: filter } = useSelector((state) => state.filter);
  const { orders, count } = useSelector((state) => state.order);
 
  const { Option } = Select;
  const [form] = Form.useForm(); 
  const [loading, setLoading] = useState(false)
  const [cargoCompanies, setCargoCompanies] = useState([]);
  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState(ADMIN_ACTIVE_ORDER_STATUSES);

  const allOrderStatusWithTranslations = ALL_ORDER_STATUSES.map(order_status => {
    return {
      label: t(order_status.label),
      value: order_status.value
    }
  })

  const setFilter = useCallback((value) => {
    dispatch(setOrderFilter(value));
  }, [dispatch]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    let isCancelled = false; // Track cancellation status
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(listOrdersToState({
          filter, 
          mode: "admin_list",
          cancelToken: source.token
        }));
  
        if (!isCancelled) {
          setLoading(false); 
        }
      } catch (error) {
      } finally {
        if (!isCancelled) {
          setLoading(false);
        }
      }
    };
  
    fetchData();
    return () => {
      isCancelled = true;
      source.cancel('Operation canceled by the user.');
    };
  }, [dispatch, filter]);

  useEffect(() => {
    fetchAllCompanies()
      .then(res => {
        setCargoCompanies(res)
      });
  }, [])

  const onOrderStatusFilterChange = (checkedValues) => {
    if(checkedValues?.includes("all")) {
        const allOrderStatuses = allOrderStatusWithTranslations?.map(option => option?.value);
        setSelectedOrderStatuses(allOrderStatuses);
        setFilter({
            ...filter,
            filter: {
              status: allOrderStatuses?.filter(os => os !== "all")
            }
        });
    } else if(selectedOrderStatuses?.includes("all")) {
        setSelectedOrderStatuses([]);
        setFilter({
            ...filter,
            filter: {
              status: []
            }
        });
    } else {
        setSelectedOrderStatuses(checkedValues);
        setFilter({
            ...filter,
            filter: {
              status: checkedValues
            }
        });
    }
}


  return (
    <div className="list-orders-container">
      <div className="list-orders-header">

        <div className="list-orders-nav">
          <h2>{t('home.Orders')}</h2>
          <div className='list-orders-top-buttons'>
          <Button type="text">{t(`fields.order_status.Export`)}</Button>
            <Button type="text">{t(`fields.order_status.Import`)}</Button>
            <Button type="text">{t(`fields.order_status.Add New Order`)}</Button>
          </div>
        </div>
        <div className='list-orders-number'>
              <span style={{fontStyle: 'italic'}}>Number of orders:</span>{' ' + count}
          </div>
        <div className="list-orders-filter">
          <div className="orders-search">
            <SearchBar type="order"/>
            <OrderTrackSearchBar fieldName="bill_of_lading" placeholder={t(`fields.orders.Bill of Lading`)}/>
            <OrderTrackSearchBar fieldName="unique_order_id" placeholder={t('fields.edit_order.Unique Order ID')}/>
          </div>
          <Form
            className="order-filter-form"
            layout="inline"
            form={form}
            initialValues={{
              sort: JSON.stringify(filter?.sort),
              cargo_company: filter?.filter?.cargo_company || "all"
            }}
          >
            <Form.Item>
              <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Order Status"
                  value={selectedOrderStatuses}
                  onChange={onOrderStatusFilterChange}
                  options={allOrderStatusWithTranslations}
                  maxTagCount="responsive"
                  showArrow
              />
            </Form.Item>
            <Form.Item name="sort">
              <Select
                onChange={(e) => {
                  const newValue = JSON.parse(e);
                  setFilter({ ...filter, page: 1, sort: newValue });
                }}
                style={{ width: "200px" }}
              >
                <Option value='{"checkout_date":-1}'>{t('fields.order_status.Checkout')}: {t('sort.New to Old')}</Option>
                <Option value='{"checkout_date":1}'>{t('fields.order_status.Checkout')}: {t('sort.Old to New')}</Option>
                <Option value='{"payment_at":-1}'>{t('fields.order_status.Payment')}: {t('sort.New to Old')}</Option>
                <Option value='{"payment_at":1}'>{t('fields.order_status.Payment')}: {t('sort.Old to New')}</Option>
                <Option value='{"delivery_date":-1}'>{t('fields.order_status.Delivery')}: {t('sort.New to Old')}</Option>
                <Option value='{"delivery_date":1}'>{t('fields.order_status.Delivery')}: {t('sort.Old to New')}</Option>
                <Option value='{"shipment_date":-1}'>{t('fields.order_status.Shipment')}: {t('sort.New to Old')}</Option>
                <Option value='{"shipment_date":1}'>{t('fields.order_status.Shipment')}: {t('sort.Old to New')}</Option>
                
              </Select>
            </Form.Item>
            <Form.Item name="cargo_company">
              <Select
                onChange={(e) => {
                  if (e === "all") {
                    let removedFilter = { ...filter.filter };
                    delete removedFilter.cargo_company;
                    setFilter({ ...filter, filter: {...removedFilter } });
                  } else {
                    setFilter({ ...filter, filter: { ...filter.filter,  cargo_company: e } });
                  }
                }}
                style={{ width: "200px" }}
              >
                <Option value="all">All Cargos</Option>
                {cargoCompanies?.map((company) => (
                  <Option key={company._id} value={company._id}>
                    {company.name}
                  </Option>
                ))}
                
              </Select>
            </Form.Item>
            <Form.Item name="region">
            <Select defaultValue={'All'}
              onChange={(e) => {
                if (e === "All") {
                  let removedFilter = { ...filter.filter };
                  delete removedFilter.region;

                  setFilter({ ...filter, filter: { ...removedFilter } });
                } else {
                  setFilter({ ...filter, filter: { ...filter.filter, region: e } });

                }
              }}
            >
              <Option value="All">{t(`fields.regions.All Regions`)}</Option>
              <Option value="Europe">{t(`fields.regions.Europe`)}</Option>
              <Option value="Other Europe">{t(`fields.regions.Other Europe`)}</Option>
              <Option value="Turkey">{t(`fields.regions.Turkey`)}</Option>
              <Option value="Other Turkey">{t(`fields.regions.Other Turkey`)}</Option>
              <Option value="Neighbors">{t(`fields.regions.Neighbors`)}</Option>
              <Option value="Others">{t(`fields.regions.Others`)}</Option>
            </Select>
          </Form.Item>
          </Form>
        </div>
        <div className="list-orders-table-head">
          <div className="list-orders-table-check">*</div>
          <div className="list-orders-table-fname">{t(`fields.orders.First Name`)}</div>
          <div className="list-orders-table-lname">{t(`fields.orders.Last Name`)}</div>
          <div className="list-orders-table-status">{t(`fields.orders.Status`)}</div>
          <div className="list-orders-table-cost">{t(`fields.orders.Total Cost`)}</div>
          <div className="list-orders-table-invoice">{t(`fields.orders.Customer Pending`)}</div>
          <div className="list-orders-table-cargo-company">{t(`fields.orders.Region`)}</div>
          <div className="list-orders-table-checkout">{t(`fields.orders.Checkout Date`)}</div>
          <div className="list-orders-table-payment">{t(`fields.orders.Payment Date`)}</div>
          <div className="list-orders-table-completed">{t(`fields.orders.Completed Date`)}</div>
        </div>
      </div>
      {loading && <Spin style={{ "marginTop": "20px" }} tip={"Loading Orders..."} />}
      {orders.map((item) => {
        return (
          <Order key={item._id} item={item} />
        )
      })}
      <div className="list-orders-footer">
        <Pagination filter={filter} setFilter={setFilter} />
      </div>
    </div>
  );
}

export default Orders;
