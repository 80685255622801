import React, { useState, useEffect } from "react";
import moment from "moment";
import { Form, Input, Button, DatePicker, notification, InputNumber, Select, Modal } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import "./EditInvoice.css"
import { 
	fetchUpdate, 
	fetchCreate,
} from "../../redux/invoiceSlice";
import { fetchUploadUrl } from '../../redux/productSlice';
import { listOrders } from "../../redux/orderSlice";

import { getBase64, loncaRound, generateZip, decodeTurkishCharacters } from "../../helpers";
import { ADMIN_VALID_CUSTOMER_INVOICE_ORDER_STATUSES } from "../../constants";

const EditInvoice = ({ 
	invoice, 
	setInvoice, 
	setShowForm, 
	addNew, 
	setAddNew, 
	newForCustomer, 
	setInvoiceModal,
	setInvoiceRefresh,
	invoiceRefresh,
	invoiceType
}) => {
	const [form] = Form.useForm();
	const { Option } = Select;

	// Safari 3.0+ "[object HTMLElementConstructor]" 
	const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));

	const [customers, setCustomers] = useState([]);
	const [invoiceLink, setInvoiceLink] = useState(null);
	const [invoiceThumb, setInvoiceThumb] = useState(null);
	const [invoiceName, setInvoiceName] = useState(null);
	const [missingFieldErrorModal, setMissingFieldErrorModal] = useState(false);

	useEffect(() => {
		if(addNew || newForCustomer) setInvoice({});
	}, [setInvoice, addNew, newForCustomer]);

	useEffect(() => {
		if(addNew) {
			listOrders({
				filter : {
					filter: { status: { $in: ADMIN_VALID_CUSTOMER_INVOICE_ORDER_STATUSES } },
					sort : { payment_at : -1 }
				},
				mode: "admin_list"
				})
				.then((res) => {
					const duplicates = res?.data?.data.map((i) => ({
						_id: i.customer._id,
						firstName: i.customer.firstName,
						lastName: i.customer.lastName,
					  }));
					  
					  const uniqueCustomers = Array.from(
						new Set(duplicates.map((customer) => JSON.stringify(customer))),
						(str) => JSON.parse(str)
					  );
					  setCustomers(uniqueCustomers)
				})
				.catch((err) => console.log(err));
			setInvoice(i => ({ ...i, total_cost: 0, subtotal_cost: 0, discount: 0 }))
		}
	}, [addNew, setInvoice])

	const handleSubmit = () => {
		if(!invoice.code || !invoice.customer || !invoiceLink) {
			setMissingFieldErrorModal(true);
			return;
		}

		const extension = invoiceLink?.type === "application/pdf" ? "pdf" : "jpg";

		fetchUploadUrl(`invoice/${invoice.code}-english.${extension}`, invoiceLink?.type)
			.then(async response => {
				await fetch(response.data, {
					method: "PUT",
					body: invoiceLink,
				})
			
				let imageUrl = response.data.split('?')[0];
				setInvoice({ ...invoice, link: imageUrl });
				notification['success']({
					message: 'Invoice uploaded to AWS successfully',
				});

				if(addNew || newForCustomer) {
					fetchCreate({ ...invoice, customer: invoice?.customer?._id, link: imageUrl, type: invoiceType || "" })
					.then((res) => {
						notification['success']({
							message: 'Invoice created successfully',
						});
						setInvoiceLink("");
						setInvoiceName("");
						setInvoiceThumb("");
						setShowForm(false);
						if(newForCustomer) {
							setInvoiceModal(true);
							setInvoiceRefresh(!invoiceRefresh);
						}
					})
					.catch((err) => {
						console.log(err)
						setInvoiceLink("");
						setInvoiceName("");
						setInvoiceThumb("");
						notification['error']({
							message: 'Invoice couldn\'t created successfully',
						});
					});
				} else {
					fetchUpdate(invoice._id, {...invoice, customer: invoice.customer._id, link: imageUrl, type: invoiceType || "" })
						.then((res) => {
							setInvoiceLink("");
							setInvoiceName("");
							setInvoiceThumb("");
							notification['success']({
								message: 'Invoice saved successfully',
							});
						})
						.catch((err) => {
							setInvoiceLink("");
							setInvoiceName("");
							setInvoiceThumb("");
							console.log(err)
							notification['error']({
								message: 'Invoice couldn\'t saved successfully',
							});
						});
				}
			})
			.catch(err => {
				notification['error']({
					message: 'Invoice couldn\'t uploaded to AWS successfully',
				});
				console.log(err);
			})
	};

	const handleDownload = async () => {
		try {
			const file_name = invoice.code + " " + invoice?.customer?.firstName + " " + invoice?.customer?.lastName + "  Invoice"
			generateZip([invoice?.link], file_name)
		} catch(err) {
			console.log(err)
		}
	}

	useEffect(() => {
		if(isSafari) {
			document.getElementById("invoice-embed").style.display = "none";
			document.getElementById("invoice-img").style.display = "inline";
			return;
		}

		let isPdf = true;

		if(invoiceThumb) {
			isPdf = invoiceLink?.type === "application/pdf";
		} else {
			isPdf = invoice?.link?.slice(invoice?.link?.length - 3) === "pdf";
		}

		if(isPdf) {
			document.getElementById("invoice-embed").style.display = "inline";
			document.getElementById("invoice-img").style.display = "none";
			document.getElementById("invoice-embed")?.contentDocument?.location?.reload(true);
		} else {
			document.getElementById("invoice-embed").style.display = "none";
			document.getElementById("invoice-img").style.display = "inline";
		}

	}, [invoiceThumb, invoice?.link, isSafari, invoiceLink])

	return(
		<div className="container">
			<div className="coupon-details">
				<Modal 
					title="Missing Fields Warning"
					visible={missingFieldErrorModal}
					onCancel={() => setMissingFieldErrorModal(false)}
					footer={[
						<Button onClick={() => setMissingFieldErrorModal(false)}>Ok</Button>
					]}
				>
					<p>You must fill code, customer and invoice fields.</p>
				</Modal>
				<Form
					form={form}
					className="coupon-form"
					onFinish={handleSubmit}
					layout="vertical"
				>
					<div className="header-save">
						<h2 className="coupon-header">{invoice?.code ? invoice.code : "Code"}</h2>
						<div>
							<Button type="primary" htmlType="submit" style={{ marginRight: "10px" }}>
								Save
							</Button>
							<Button type="secondary" onClick={() => {
								setShowForm(false);
								if(setAddNew) setAddNew(false);
							}}>
								Cancel
							</Button>
						</div>
					</div>
					<div className="form-top">
						<div className="form-left">
							<div className="inline-inputs">
								<Form.Item label="Code">
									<Input	
									value={invoice?.code}
									onChange={(e) => {
										const updatedCode = decodeTurkishCharacters(e.target.value)
										setInvoice({...invoice, code: updatedCode.trim().replaceAll(" ", "").toUpperCase()})
									}}
									/>
								</Form.Item>
								{addNew ?
									<Form.Item label="Customer">
										<Select
											required
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) =>
												option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onChange={(e) => {
												setInvoice({
													...invoice, 
													customer: customers.find((i) => i._id === e)?._id
												})
											}}
										>
											{ customers?.map((customer, index) => (
												<Option key={customer._id + index} value={customer._id}>{customer.firstName + " " + customer.lastName}</Option>
											)) }
										</Select>
									</Form.Item> :
									<Form.Item label="Customer">
										<Input 
											value={invoice?.customer?.firstName + " " + invoice?.customer?.lastName} 
											disabled
										/>
									</Form.Item>
								}
							</div>
							<div style={{ width: "calc(50% - 5px)" }}>
								<Form.Item label="Issue Date">
									<DatePicker
										showTime
										value={invoice?.issue_date ? moment(invoice?.issue_date) : null}
										onChange={(e) => {
											setInvoice({...invoice, issue_date: new Date(e._d)})
										}}
										style={{ width: "100%" }}
									/>
								</Form.Item>
							</div>
							<div className="inline-inputs">
								<Form.Item label="Subtotal Cost">
									<InputNumber
										prefix="$"
										value={invoice?.subtotal_cost ? loncaRound(invoice?.subtotal_cost) :  0.00} 
										onChange={(e) => {
											setInvoice({
												...invoice, 
												subtotal_cost: loncaRound(e),
												total_cost: loncaRound(loncaRound(e) - (invoice.discount ? invoice.discount : 0))
											})
										}}
										style={{ width: "100%" }}
									/>
							  </Form.Item>
								<Form.Item label="Total Cost">
									<InputNumber
										prefix="$"
										value={invoice?.total_cost ? loncaRound(invoice?.total_cost) : 0.00} 
										disabled
										style={{ width: "100%" }}
									/>
							  </Form.Item>
							</div>
							<div style={{ width: "calc(50% - 5px)" }}>
								<Form.Item label="Discount">
									<InputNumber
										prefix="$"
										value={invoice?.discount ? invoice.discount : 0} 
										onChange={(e) => {
											const newDiscount = e ? loncaRound(e) : 0;
											setInvoice({
												...invoice, 
												discount: newDiscount,
												total_cost: loncaRound(invoice.subtotal_cost - newDiscount)
											})
										}}
										style={{ width: "100%" }}
									/>
							  </Form.Item>
							</div>
						</div>
						<div className="form-right">
							<label class="custom-file-upload">
								<input type="file" onChange={async (e) => {
									setInvoiceName(e.target.files[0].name)
									setInvoiceLink(e.target.files[0]);

									const thumb = await getBase64(e.target.files[0]);
									setInvoiceThumb(thumb);
								}}/>
								<UploadOutlined />
								{"  Upload Invoice"}
							</label>

							<div className="invoice-img-container">
								<iframe title="invoice" style={{display: "none"}} id="invoice-embed" width={100} src={invoiceThumb ? invoiceThumb : `${invoice?.link}?${new Date().getTime()}`} />
								<img alt="invoice" style={{display: "none"}} id="invoice-img" width={100} src={invoiceThumb ? invoiceThumb : `${invoice?.link}?${new Date().getTime()}`} />
								<div>{invoiceLink ? `FILE: ${invoiceName}` : invoice?.link ? `FILE: ${invoice.link}` : ""}</div>
							</div>

							<Button onClick={() => handleDownload()}>
								<DownloadOutlined />
								Download Current Invoice
							</Button>
						</div>
					</div>
				</Form>
			</div>
		</div>
	)
};

export default EditInvoice;