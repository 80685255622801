import { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, notification } from 'antd';
import { ColorSelector, GenderSelector, TagSelector, SizeChartUploader, SeriesSelector, VendorSelector, ProductTypeSelector, StatusSelector, ScraperGroup, NameInputs, StockInformation, TrDescriptionEditor, PriceBox, MeasurementsEditor, ProductGroupEditor, StockBoxEditor, ImageBox, CheckboxComponent, UpdateConfigEditor, StockInformationSubSeries, VideoBox } from "../../components";
import { useParams } from "react-router-dom";
import _ from 'underscore';

import usePrompt from "../../hooks/usePrompt";

import "./EditProduct.scss"
import { getChangedValues, loncaRound, getBase64, uploadMedias, uploadChartImage, isMediaListExceedSizeThreshold, checkIsAllowedToSeeForProductPage } from "../../helpers"
import { fetchUpdateProduct, fetchCreateProduct, fetchProductByHandleOrId } from "../../redux/productSlice";
import { fetchExchangeRates } from "../../redux/exchangeRatesSlice";
import { fetchVendors } from "../../redux/vendorSlice";
import { fetchGroupById, fetchInsertGroup, fetchUpdateGroupById } from "../../redux/productGroup";
import { useTranslation } from "react-i18next";
import InTransitInfoEditor from "../../components/InTransitInfoEditor/InTransitInfoEditor";

const EditProduct = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { allSeries } = useSelector((state) => state.series);
  const { vendors: vendorsMain } = useSelector((state) => state.vendors);
  const { tags } = useSelector((state) => state.tags);
  const { user } = useSelector((state) => state.user);
  
  const [form] = Form.useForm();

  const [searchParams, _] = useSearchParams();
  const { id } = useParams();

  const [tab, setTab] = useState("home");
  const [TL_USD_BUYING, setTL_USD_BUYING] = useState();
  const [TL_USD_SELLING, setTL_USD_SELLING] = useState();
  const [product, setProduct] = useState(null);
  const [skuCode, setSkuCode] = useState("");
  const [vendors, setVendors] = useState(vendorsMain.filter((i) => i.status === "Active"));
  const [pricetl, setPricetl] = useState(0);
  const [priceTurkiyetl, setPriceTurkiyetl] = useState(0);
  const [priceusd, setPriceusd] = useState(0);
  const [priceTurkiyeusd, setPriceTurkiyeusd] = useState(0);
  const [cogstl, setCogstl] = useState(0);
  const [cogsTurkiyetl, setCogsTurkiyetl] = useState(0);
  const [cogsusd, setCogsusd] = useState(0);
  const [cogsTurkiyeusd, setCogsTurkiyeusd] = useState(0);
  const [measurement, setMeasurement] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [discountCheckbox, setDiscountCheckbox] = useState(false);
  const [discountTurkiyeCheckbox, setDiscountTurkiyeCheckbox] = useState(false);
  const [discountTL, setDiscountTL] = useState(0);
  const [discountTurkiyeTL, setDiscountTurkiyeTL] = useState(0);
  const [discountUSD, setDiscountUSD] = useState(0);
  const [discountTurkiyeUSD, setDiscountTurkiyeUSD] = useState(0);
  const [discountCogsTL, setDiscountCogsTL] = useState(0);
  const [discountCogsTurkiyeTL, setDiscountCogsTurkiyeTL] = useState(0);
  const [discountCogsUSD, setDiscountCogsUSD] = useState(0);
  const [discountCogsTurkiyeUSD, setDiscountCogsTurkiyeUSD] = useState(0);
  const [stockBox, setStockBox] =  useState([]);
  const [inTransitInfo, setInTransitInfo] =  useState([]);
  const [handle, setHandle] = useState("new");
  const [fileList, setFileList] = useState([]);
  const [newImagesList, setNewImagesList] = useState([]);
  const [videoFileList, setVideoFileList] = useState([]);
  const [chartFileList, setChartFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewType, setPreviewType] = useState('image');
  const [notRemovedScraperCount, setNotRemovedScraperCount] = useState(0);
  const [uniqueKey, setUniqueKey] = useState('');
  const [scraperGroupRemoved, setScraperGroupRemoved] = useState([]);
  const [scraperGroup, setscraperGroup] = useState([]);
  const [productGroup, setProductGroup] = useState({ products: [] });
  const [productGroupDirty, setProductGroupDirty] = useState(false);
  const [productGroupRemoved, setProductGroupRemoved] = useState([]);
  const [changedStockBox, setChangedStockBox] = useState([]);
  const [loading, setLoading] = useState(false);
  const [compoundMargin, setCompoundMargin] = useState();
  const [priceInitial, setPriceInitial] = useState(0);
  const [statusInitial, setStatusInitial] = useState("");
  const [seriesInitial, setSeriesInitial] = useState("");
  const [productTypeIdInitial, setProductTypeIdInitial] = useState(""); 
  const [refresh, setRefresh] = useState(false);
  const [isAllowed, setIsAllowed] = useState(true);

  usePrompt("You have unsaved changes, do you want to leave?", isDirty);

  useEffect(() => {
    dispatch(fetchVendors());
  }, [])

  useEffect(() => {
    setVendors(vendorsMain.filter((i) => i.status === "Active"));
  }, [vendorsMain]);

  useEffect(() => {
    setTab(searchParams?.get("tab") || "home");
}, [searchParams?.get("tab")]);

  useEffect(() => {
    if (product) {
      if (product?.vendor) {
        const product_vendor = typeof product?.vendor === "object" ? product.vendor?._id : product.vendor;
        const selectedVendor = vendors?.find((vendor) => vendor?._id === product_vendor);
        setVendors(vendors => [
          selectedVendor,
          ...vendors.filter((vendor) => vendor?._id !== selectedVendor?._id)
        ]);
      }
    }
  }, [product?.vendor]);


  useEffect(() => {
    let hasStock;
    let hasSupplierStock;
    
    if(product?.sub_series?.length === 0){
      hasStock = product?.stock > 0;
      hasSupplierStock = product?.supplier_stock > 0;
    }else{
      hasStock = !!product?.sub_series?.find(subSerie => subSerie?.stock > 0);
      hasSupplierStock = !!product?.sub_series?.find((subSerie) => subSerie?.supplier_stock > 0);
    }
    
    const isActive =  product?.status === 'Active';

    const hasStockYetNotActive = (hasStock || hasSupplierStock) && !isActive;
    const hasNoStockYetActive = (!hasStock && !hasSupplierStock) && isActive;
    
    if(hasStockYetNotActive){
      setProduct({...product, status:'Active', update_config: {...product?.update_config, status: ''}})
    }else if(hasNoStockYetActive){
      setProduct({...product, status:'Passive', update_config: {...product?.update_config, status: ''} })
    }

  }, [product?.stock, product?.supplier_stock, product?.sub_series])


  
  useEffect(() => {
    setSkuCode(createSKU(handle, product?.vendor, vendors));
  }, [handle, vendors, product?.vendor]);

  useEffect(() => {
    setProduct(product => ({
      ...product,
      names : {...product?.names, tr : createSKU(handle, product?.vendor, vendors) + " - " + createNameWithoutSku(product?.names?.tr)}
    }))
  }, [skuCode]);

  useEffect(() => {
    const updatedLastScraperCheck = scraperGroup.length - scraperGroupRemoved.length;
    setNotRemovedScraperCount(updatedLastScraperCheck);
  }, [scraperGroup, scraperGroupRemoved]);

  useEffect(() => {
    const priceForTag = discountCheckbox ? discountUSD : priceusd
    if(priceForTag){

      let tagName;
  
      if (priceForTag < 10) {
      tagName = 'Under $10';
    } else if (priceForTag < 20) {
      tagName = 'Between $10-$20';
    } else if (priceForTag < 30) {
      tagName = 'Between $20-$30';
    } else if (priceForTag < 40) {
      tagName = 'Between $30-$40';
    } else if (priceForTag < 50) {
      tagName = 'Between $40-$50';
    } else {
      tagName = 'Above $50';
    }
      
      const tagExists = product?.tags?.find((tag) => tag?.name == tagName);
  
      if(!tagExists){
        let newTags = product?.tags?.filter(tag => tag?.type != 'price')  
        
        const newPriceTags = tags?.find(tag => tag?.name == tagName)  
        newTags.push(newPriceTags)
        
        setProduct({...product, tags: newTags })
      }
  
    }
    
  },[priceusd, discountUSD, discountCheckbox])

   
  useEffect(() => {
    let newTags = product?.tags
    const isRestock = statusInitial === 'Passive' && product?.status === 'Active'
    const isAlreadyRestock  = newTags?.find(tag => tag?.name === 'RPT')
    if(isRestock){  
      if(!isAlreadyRestock){
        const restockTag = tags?.find(tag => tag?.name === 'RPT')
        newTags.push(restockTag)
        setProduct({...product, tags: newTags })
      }
    }else{
      newTags = newTags?.filter(tag => tag?.name !== 'RPT')
      setProduct({...product, tags: newTags })
    }
  },[statusInitial, product?.status])

  useEffect(() => {
    setIsAllowed(checkIsAllowedToSeeForProductPage(user?.admin_role));
  }, [user])


  const createSKU = (handle, vendor, vendors) => {
    const selectedVendor = vendors?.find(v => v?._id?.toString() === (vendor?._id || vendor)?.toString());
    if (handle === "new") {
      return `${selectedVendor?.vendor_initial || ""}${(selectedVendor?.vendor_product_count || 10000) + 1}`;
    } else {
      const isOld = !isNaN(handle?.split("-")[0][0]);
      const current_count = isOld ? handle?.split("-")[0] : handle?.split("-")[0]?.slice(3);
      return `${!isOld && selectedVendor?.vendor_initial ? selectedVendor?.vendor_initial : ""}${current_count}`;
    }
  }
  const updateTabParam = (newTab) => {
    const newUrl = `${location.pathname}?tab=${newTab}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setTab(newTab);
  };

  useEffect(() => {
    setCompoundMargin(((1 + (product?.vendor?.lonca_vendor_margin / 100)) * (1 + (product?.vendor?.lonca_boutique_margin / 100))))
  }, [product?.vendor]);

  useEffect(() => {
      let tlUsdSelling = TL_USD_SELLING;

    if(!TL_USD_BUYING || !TL_USD_SELLING) {
      fetchExchangeRates({ abbreviation: "TRY" })
        .then(res => {
          setTL_USD_BUYING(res?.data?.rate_usd_buying)
          setTL_USD_SELLING(res?.data?.rate_usd_selling)
          tlUsdSelling = res?.data?.rate_usd_selling;
        });
    };
    if (id !== "new"){
      fetchProductByHandleOrId({ mode : 'edit_product', id: id})
      .then((res) => {
        form.setFieldsValue({
          status: res.data.status,
          vendor: res.data.vendor?._id,
          series: res.data.series?.name,
          productType: res.data.product_type?.names?.en,
          ranking: res.data.ranking,
          stock: res.data.stock,
          supplier_stock: res.data.supplier_stock,
          supplier_stock_code: res.data?.supplier_stock_code,
          in_transit_stock: res.data?.in_transit_stock,
          reserved_stock: res.data?.reserved_stock,
          supplier_link: res.data?.supplier_link
        });

        setPriceInitial(res.data?.price);
        setStatusInitial(res.data?.status);
        setSeriesInitial(res.data?.series?._id);
        setProductTypeIdInitial(res.data?.product_type?._id);

        setHandle(res.data?.handle);
        setProduct(res.data);
        setPricetl(loncaRound(res.data?.price * tlUsdSelling));
        setPriceTurkiyetl(loncaRound(res.data?.price_tr * tlUsdSelling));
        setPriceusd(loncaRound(res.data?.price));
        setPriceTurkiyeusd(loncaRound(res.data?.price_tr));
        setCogstl(loncaRound(res.data.cogs * tlUsdSelling));
        setCogsTurkiyetl(loncaRound(res.data.cogs_tr * tlUsdSelling));
        setCogsusd(loncaRound(res.data.cogs));
        setCogsTurkiyeusd(loncaRound(res.data.cogs_tr));
        setMeasurement(res.data?.measurement);
        setscraperGroup(res.data?.scrapers);
        setUniqueKey(res.data?.uniquekey?.scraper_unique_key);
        setDiscountCheckbox(!!res.data?.is_discounted);
        setDiscountTurkiyeCheckbox(!!res.data?.is_discounted_tr);
        setDiscountTL(loncaRound(res.data?.discounted_price * tlUsdSelling) );
        setDiscountTurkiyeTL(loncaRound(res.data?.discounted_price_tr * tlUsdSelling) );
        setDiscountUSD(loncaRound(res.data?.discounted_price ));
        setDiscountTurkiyeUSD(loncaRound(res.data?.discounted_price_tr ));
        setDiscountCogsTL(loncaRound(res.data.discounted_cogs * tlUsdSelling));
        setDiscountCogsTurkiyeTL(loncaRound(res.data.discounted_cogs_tr * tlUsdSelling));
        setDiscountCogsUSD(loncaRound(res.data.discounted_cogs));
        setDiscountCogsTurkiyeUSD(loncaRound(res.data.discounted_cogs_tr));
        setFileList(res.data?.images?.map((image, index) => {
          return {
            uid: index + 1,
            status: "done",
            url: image,
            name: `Image_${index + 1}`,
            existBeforeUpdate: true,
            is_main: res.data?.main_image === image
          }
        }));
        setNewImagesList(res.data?.image_update_log?.new_images?.map((image, index) => {
          return {
            uid: index + 1,
            status: "done",
            url: image,
            name: `Image_${index + 1}`,
            existBeforeUpdate: false,
            is_main: res.data?.main_image === image
          }
        }));
      
        setVideoFileList(res.data?.videos?.map((video, index) => {
          return {
            uid: index + 1,
            status: "done",
            url: video,
            name: `Video_${index + 1}`,
            existBeforeUpdate: true,
          }
        }));

        setCompoundMargin(((1 + (res.data?.vendor?.lonca_vendor_margin / 100)) * (1 + (res.data?.vendor?.lonca_boutique_margin / 100))))

        if (res.data?.size_chart) setChartFileList([{
          uid: 1,
          status: "done",
          url: res.data?.size_chart,
          name: `Size Chart Image`,
          existBeforeUpdate: true
        }])
        if (res.data.product_group !== undefined) {
          fetchGroupById(res?.data?.product_group?._id)
            .then((res) => setProductGroup(res.data))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
    } 
    if (handle === "new") {
      setProduct({
        tags: [],
        color: [],
        gender: [],
      });
    }
  }, [id, form, refresh]);

  const handleSubmit = async () => {
    setLoading(true);
    const hasEmptyStockBox = stockBox?.some((box) => !box || !box?.box_id?.trim());

    const totalStock = (product?.vendor?.is_misfire && product?.sub_series?.length > 0) ? product?.sub_series?.reduce((acc, crr) => acc + crr?.stock, 0) : product?.stock;
    const totalSupplierStock = (product?.vendor?.is_misfire && product?.sub_series?.length > 0) ? product?.sub_series?.reduce((acc, crr) => acc + crr?.supplier_stock, 0) : product?.supplier_stock;
    const limit = (product?.vendor?.is_misfire && product?.sub_series?.length && product?.vendor?.misfire_limit > 0) ? product?.vendor?.misfire_limit : 1;

    // stock - status check
    if (!(totalStock >= limit) && !(totalSupplierStock >= limit) && product?.status === "Active") {
      notification['error']({
        message: (product?.sub_series?.length && product?.vendor?.misfire_limit > 0) 
          ? `${t(`errors.Minimum required total stock`)}: ${limit}`
          : t(`errors.Active products must have either stock or supplier stock.`),
      });

      setLoading(false);
      return;
    }

    //stock box check
    if(product?.status !== "Draft" && ((product?.stock > 0 && stockBox?.length !== product?.stock + product?.reserved_stock) || hasEmptyStockBox)){
      notification['error']({
        message: t(`errors.Stock Box count must be equal to product stock and stock box id can't be empty`),
        });
        setLoading(false);
        return;
    }
    //in transit info check
    if(product?.status !== "Draft" && ((inTransitInfo?.length < product?.in_transit_stock) )){
      notification['error']({
        message: t(`errors.In transit stock and in transit info count must be equal`),
        });
        setLoading(false);
        return;
    }

    // price cannot be smaller than 0
    if(( pricetl <= 0) || (priceusd <= 0) || (cogstl <= 0) || (cogsusd <= 0)) {
      notification['error']({
        message: t(`errors.Price can't be smaller than 0`),
        });
        setLoading(false);
        return;
    }

    // discounted price cannot be smaller than 0
    if(discountCheckbox && ((discountTL <= 0) || (discountUSD <= 0) || (discountCogsTL <= 0) || (discountCogsUSD <= 0))) {
      notification['error']({
        message: t(`errors.Discounted price can't be smaller than 0`),
        });
        setLoading(false);
        return;
    }

    // discounted price cannot be bigger than price
    if(discountCheckbox && ((discountTL >= pricetl) || (discountUSD >= priceusd) || (discountCogsTL >= cogstl) || (discountCogsUSD >= cogsusd))) {
      notification['error']({
        message: t(`errors.Discount Price can't be bigger than product price`),
        });
        setLoading(false);
        return;
    }
    // series cannot be empty
    if((product.series === undefined || product.series === null || product.series === "") 
    && (product.status === "Active")) {
      notification['error']({
        message: t(`errors.Series can't be empty`),
        });
        setLoading(false);
        return;
    }
    // new product season tag check
    const seasonTag = product?.tags?.find((tag) => tag?.type?.includes("season"));
    if((handle === 'new') && (product?.status === "Active" || product?.status === "Passive") && !seasonTag) {
      notification['error']({
        message: t(`errors.Product needs a seasonal tag`),
        });
        setLoading(false);
        return;
    }

    let changed = getChangedValues(product);
    
    let logs = [];
    
    if(product?.price !== priceInitial) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Price",
        from: priceInitial,
        to: product?.price
      });
    }
  
    if(product?.status !== statusInitial) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Status",
        from: statusInitial,
        to: product?.status
      });
    }

    if(!product?.series?._id && (product?.series !== seriesInitial)) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Series",
        from: seriesInitial,
        to: product?.series
      });
    }

    if(product?.product_type?._id !== productTypeIdInitial) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Product Type",
        from: productTypeIdInitial,
        to: product?.product_type?._id
      });
    }
        
    if(logs.length !== 0) {
      changed = { ...changed, change_log: [ ...changed["change_log"], ...logs ] };
    }

    for(let box of stockBox){
      delete box?.tempType; // remove temp value
    }

    changed = { 
      ...changed, 
      stock_box: stockBox, 
      in_transit_info: inTransitInfo, 
      series: allSeries?.find(s => s?._id?.toString() === changed?.series)
    };

    changedStockBox?.length > 0 && (changed = { ...changed, changed_stock_box : changedStockBox });
    
    if(handle === "new") {
      let productEnterLog = {
        role: user?.role,
        user: user?.full_name,
        type: "New Product"
      }
      changed = { ...changed, product_enter_log: productEnterLog };
    };

    const skuForUplaod = handle !== "new" ? product?.names?.en?.split('-')[0].trim() : skuCode;
    let imageLinks = [];
    let videoLinks = [];
    let selectedImage = fileList?.find(f => f?.is_main);
    let selectedImageLink = "";

    let existBeforeUpdateMainIndex = fileList?.findIndex(f => f?.is_main);
    let existBeforeUpdateCountTillIndex = fileList?.slice(0, existBeforeUpdateMainIndex + 1)?.filter(f => f?.existBeforeUpdate)?.length;

    if (fileList?.filter(i => !i?.existBeforeUpdate)?.length) imageLinks = await uploadMedias(
      vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.name,
      fileList?.filter(i => !i?.existBeforeUpdate),
      skuForUplaod,
      fileList?.filter(i => i?.existBeforeUpdate)?.length
    );

    if (videoFileList?.filter(i => !i?.existBeforeUpdate)?.length) {
      if(isMediaListExceedSizeThreshold(videoFileList?.filter(i => !i?.existBeforeUpdate))){
        notification['error']({
          message: "File is bigger than 100 MB. Can't upload",
        });
        setLoading(false)
        return
      }

      videoLinks = await uploadMedias(
      vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.name,
      videoFileList?.filter(i => !i?.existBeforeUpdate),
      skuForUplaod,
      videoFileList?.filter(i => i?.existBeforeUpdate)?.length,
      "product",
      'video'
    );

  }
    if (selectedImage?.existBeforeUpdate) {
      selectedImageLink = selectedImage?.url;
    } else {
      selectedImageLink = imageLinks[existBeforeUpdateMainIndex - existBeforeUpdateCountTillIndex];
    }

    if (product?.status !== "Draft" && fileList.length === 0) {
      notification['error']({
        message: 'You need to upload at least one product image',
      });
      setLoading(false);
      return
    }

    if (fileList.find(image => !image?.existBeforeUpdate)) {
      if (imageLinks.length === fileList?.filter(i => !i?.existBeforeUpdate).length) {
        notification['success']({
          message: 'All images loaded to AWS successfully',
        });
      } else {
        notification['error']({
          message: 'All images couldn\'t loaded to AWS successfully',
        });
      };
    }

    imageLinks = [...(fileList?.filter(i => i?.existBeforeUpdate)?.map(image => image?.url) || []), ...(imageLinks || [])];

    if (videoFileList.find(image => !image?.existBeforeUpdate)) {
      if (videoLinks.length === videoFileList?.filter(i => !i?.existBeforeUpdate).length) {
        notification['success']({
          message: 'All videos loaded to AWS successfully',
        });
      } else {
        notification['error']({
          message: 'All videos couldn\'t loaded to AWS successfully',
        });
      };
    }

    videoLinks = [...(videoFileList?.filter(i => i?.existBeforeUpdate)?.map(image => image?.url) || []), ...(videoLinks || [])];

    let chartImage = "";

    if (chartFileList?.filter(i => !i?.existBeforeUpdate)?.length) chartImage = await uploadChartImage(
      vendors?.find((i) => i?._id === product?.vendor?._id || i?._id === product?.vendor)?.name,
      chartFileList,
      skuForUplaod
    );

    if (handle !== "new") {
      fetchUpdateProduct(product._id, changed, selectedImageLink, imageLinks, chartImage, videoLinks)
        .then((res) => {
          setLoading(false);
          notification['success']({
            message: 'Product saved successfully',
          });

          setProduct({ ...product, change_log: res?.data?.change_log });
          setPriceInitial(res?.data?.price);
          setStatusInitial(res?.data?.status);
          if(!res?.data?.series?._id){
             setSeriesInitial(res?.data?.series);  
          }

          changedStockBox?.length > 0 && setChangedStockBox([]);
          if (productGroupDirty) {
            fetchSubmitGroup().then(() => {
              notification['success']({
                message: 'Product group saved successfully',
              });
            }).catch((err) => {
              console.log(err)
              notification['error']({
                message: 'Product group couldn\'t saved successfully',
              });
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          notification['error']({
            message: 'Product couldn\'t saved successfully',
          });
        });
    }
    if (handle === "new") {
      const selectedVendor = vendors?.find(v => v?._id?.toString() === (product?.vendor?._id || product?.vendor)?.toString());
      fetchCreateProduct(changed, imageLinks, selectedImageLink, chartImage, videoLinks, selectedVendor?.vendor_product_count)
        .then((res) => {
          setLoading(false);
          notification['success']({
            message: 'Product created successfully',
          });
          dispatch(fetchVendors());
          navigate("/products");
        })
        .catch((err) => {
          setLoading(false);
          if(err?.response?.status === 409) {
            notification['error']({
              message: 'You have possibly created a product for the same vendor in a separate tab! Refreshing the page...',
            });
            dispatch(fetchVendors());
          } else {
            notification['error']({
              message: 'Product couldn\'t created successfully',
            });
          }
        });
    }
    setLoading(false);
    setIsDirty(false);
  }

  
  const fetchSubmitGroup = async () => {
    if (!productGroup._id) {
      await fetchInsertGroup({ ...productGroup, products: [...productGroup?.products, product._id] })
    } else {
      await fetchUpdateGroupById(productGroup._id, productGroup)
    }

    setRefresh(r => !r);
  }

  const handlePreview = async (file, type) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewType(type);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const createNameWithoutSku = (name) => {
    const dashArr = name?.split("-");

    if (dashArr?.length) {
      return dashArr?.slice(1)?.join("-")?.trimStart();
    }

    return "";
  }

  const handleHideChange = () => {
    setIsDirty(true);

    setProduct((prevProduct) => ({
      ...prevProduct,
      hide_product: !prevProduct?.hide_product, 
    }));

};

const handleNeedPhotoShoot = () => {
  setIsDirty(true);

  setProduct((prevProduct) => ({
    ...prevProduct,
    need_photo_shoot: !prevProduct?.need_photo_shoot, 
  }));

};


if(user?.admin_role === "Social Media Manager"){
  return (
    <div className="container" onClick={(e) => e.stopPropagation()}>
      <div className="product-details">
        <Form
          form={form}
          className="product-form"
          layout="vertical"
        >

          <div className="header-save product-header-save">
            <h2 className="product-header">{product?.names?.en}</h2>
          </div>

          <ImageBox
            fileList={fileList}
            product={product}
            handlePreview={(file) => handlePreview(file, 'image')}
            setFileList={setFileList}
            getBase64={getBase64}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewType={previewType}
            previewImage={previewImage}
            setPreviewVisible={setPreviewVisible}
            isEditable={false}
          />

          <VideoBox
            fileList={videoFileList}
            product={product}
            handlePreview={(file) => handlePreview(file, 'video')}
            setFileList={setVideoFileList}
            getBase64={getBase64}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewType={previewType}
            previewImage={previewImage}
            setPreviewVisible={setPreviewVisible}
            isEditable={false}
          />
          
        </Form>
      </div>
    </div>
  )
}

  return (
    <div className="container" onClick={(e) => e.stopPropagation()}>
      <div className="product-details">
        <Form
          form={form}
          className="product-form"
          onFinish={handleSubmit}
          layout="vertical"
        >

          <div className="header-save product-header-save">
            <div className="product-tabs">
              <div onClick={() => updateTabParam(`home`)} className={tab === "home" && "selected"}>Home</div>
            </div>
            <h2 className="product-header">{product?.names?.tr}</h2>
            <div>
              <Button type="primary" loading={loading} className="button-margin" htmlType="submit" style={{ marginRight: "10px" }}>
                Save
              </Button>
              <Button type="secondary" onClick={() => navigate("/products")}>
                Cancel
              </Button>
            </div>
          </div>
          
          <section>
          <div className="form-top">
            <div className="form-left">

              <NameInputs product={product} setProduct={setProduct} setIsDirty={setIsDirty} createNameWithoutSku={createNameWithoutSku} handle={handle} vendors={vendors} skuCode={skuCode} isAllowed={isAllowed}/>

              {isAllowed && <CheckboxComponent
                  type="hide_product"
                  checked={product?.hide_product}
                  onChange={handleHideChange}
              />}

              {isAllowed && <CheckboxComponent
                type="need_photo_shoot"
                checked={product?.need_photo_shoot}
                onChange={handleNeedPhotoShoot}
              />}

              { product?.sub_series?.length > 0  && product?.vendor?.is_misfire ?
                <StockInformationSubSeries product={product} setProduct={setProduct} setIsDirty={setIsDirty} user={user} changedStockBox={changedStockBox} setChangedStockBox={setChangedStockBox} isAllowed={isAllowed}/>
                :
                <>
                  <StockInformation product={product} setProduct={setProduct} setIsDirty={setIsDirty} isAllowed={isAllowed}/>
                  <StockBoxEditor stockBox={stockBox} setStockBox={setStockBox} product={product} setIsDirty={setIsDirty} user={user}
                    setProduct={setProduct} changedStockBox={changedStockBox} setChangedStockBox={setChangedStockBox} />
                  <InTransitInfoEditor inTransitInfo={inTransitInfo} setInTransitInfo={setInTransitInfo} 
                    product={product} setIsDirty={setIsDirty} user={user} stockBox={stockBox}
                    setStockBox={setStockBox} setProduct={setProduct}
                    changedStockBox={changedStockBox} setChangedStockBox={setChangedStockBox}
                  />
                </>
                
              }

              {isAllowed && <TrDescriptionEditor product={product} setProduct={setProduct} setIsDirty={setIsDirty} />}

              {isAllowed && <PriceBox
                discountCheckbox={discountCheckbox}
                discountTurkiyeCheckbox={discountTurkiyeCheckbox}
                setDiscountCheckbox={setDiscountCheckbox}
                setDiscountTurkiyeCheckbox={setDiscountTurkiyeCheckbox}
                product={product}
                setProduct={setProduct}
                setIsDirty={setIsDirty}
                TL_USD_BUYING={TL_USD_BUYING}
                TL_USD_SELLING={TL_USD_SELLING}
                compoundMargin={compoundMargin}
                pricetl={pricetl}
                priceTurkiyetl={priceTurkiyetl}
                setPricetl={setPricetl}
                setPriceTurkiyetl={setPriceTurkiyetl}
                priceusd={priceusd}
                priceTurkiyeusd={priceTurkiyeusd}
                setPriceusd={setPriceusd}
                setPriceTurkiyeusd={setPriceTurkiyeusd}
                cogstl={cogstl}
                cogsTurkiyetl={cogsTurkiyetl}
                setCogstl={setCogstl}
                setCogsTurkiyetl={setCogsTurkiyetl}
                cogsusd={cogsusd}
                cogsTurkiyeusd={cogsTurkiyeusd}
                setCogsusd={setCogsusd}
                setCogsTurkiyeusd={setCogsTurkiyeusd}
                discountTL={discountTL}
                discountTurkiyeTL={discountTurkiyeTL}
                setDiscountTL={setDiscountTL}
                setDiscountTurkiyeTL={setDiscountTurkiyeTL}
                discountUSD={discountUSD}
                discountTurkiyeUSD={discountTurkiyeUSD}
                setDiscountUSD={setDiscountUSD}
                setDiscountTurkiyeUSD={setDiscountTurkiyeUSD}
                discountCogsTL={discountCogsTL}
                discountCogsTurkiyeTL={discountCogsTurkiyeTL}
                setDiscountCogsTL={setDiscountCogsTL}
                setDiscountCogsTurkiyeTL={setDiscountCogsTurkiyeTL}
                discountCogsUSD={discountCogsUSD}
                discountCogsTurkiyeUSD={discountCogsTurkiyeUSD}
                setDiscountCogsUSD={setDiscountCogsUSD}
                setDiscountCogsTurkiyeUSD={setDiscountCogsTurkiyeUSD}
              />}

              {isAllowed && <MeasurementsEditor measurement={measurement} product={product} setProduct={setProduct} setMeasurement={setMeasurement} setIsDirty={setIsDirty}/>}

              {isAllowed &&<ProductGroupEditor
                product={product}
                productGroup={productGroup}
                setProductGroup={setProductGroup}
                dispatch={dispatch}
                t={t}
                setProductGroupDirty={setProductGroupDirty}
                setIsDirty={setIsDirty}
                productGroupRemoved={productGroupRemoved}
                setProductGroupRemoved={setProductGroupRemoved}
              />}
              
              {isAllowed && <ScraperGroup
                scraperGroup={scraperGroup}
                notRemovedScraperCount={notRemovedScraperCount}
                product={product}
                setIsDirty={setIsDirty}
                scraperGroupRemoved={scraperGroupRemoved}
                setScraperGroupRemoved={setScraperGroupRemoved}
                uniqueKey={uniqueKey}
              />}

            </div>
            <div className="form-right">

              <StatusSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty} addNew={handle === "new"}/>

              <ProductTypeSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty} isAllowed={isAllowed}/>

              <VendorSelector vendors={vendors} product={product} setProduct={setProduct} setIsDirty={setIsDirty} isAllowed={isAllowed}/>

              <SeriesSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty} />

              {isAllowed && <UpdateConfigEditor product={product} setProduct={setProduct}/>}

              {<ColorSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty} />}

              {isAllowed && product?.gender && <GenderSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty}/> }

              {isAllowed && <SizeChartUploader chartFileList={chartFileList} setIsDirty={setIsDirty} setChartFileList={setChartFileList} handlePreview={handlePreview} />}

            </div>
          </div>
          {<ImageBox
            fileList={fileList}
            product={product}
            handlePreview={(file) => handlePreview(file, 'image')}
            setFileList={setFileList}
            getBase64={getBase64}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewType={previewType}
            previewImage={previewImage}
            setPreviewVisible={setPreviewVisible}
          />}

          {<VideoBox
            fileList={videoFileList}
            product={product}
            handlePreview={(file) => handlePreview(file, 'video')}
            setFileList={setVideoFileList}
            getBase64={getBase64}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewType={previewType}
            previewImage={previewImage}
            setPreviewVisible={setPreviewVisible}
          />}
          </section>

          {isAllowed && <section>
            <TagSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty} fileList={fileList} setFileList={setFileList}/>
          </section>}
            
          
        </Form>
      </div>
    </div>
  )
};

export default EditProduct;