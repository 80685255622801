import React, { useEffect, useState } from 'react';
import axios from "axios";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { Input, Button, Select, notification, Spin, Modal } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';

import {
  changeCartItem,
  calculateItemPrice,
  loncaRound,
  loncaFormatDate,
  renderCalculatedVendorCogs,
  isRoleHavePermission
} from '../../helpers';
import usePrompt from "../../hooks/usePrompt";

import { fetchCalculateAndUpdate, fetchOrderAggregate, fetchProcessDelayedItems } from '../../redux/orderSlice';

import { Pagination, ResponsiveImage } from '../../components';

import {
  ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES, 
  ADMIN_VALID_ORDER_PRODUCTS_ORDER_STATUSES, 
  ADMIN_DISABLED_ORDER_STATUSES, 
  ADMIN_DISABLED_ITEM_STATUSES,
  ADMIN_VALID_LATE_PRODUCTS_ITEM_STATUSES,
} from '../../constants';

import './OrderProducts.css';
import { saveAs } from 'file-saver';

function OrderProducts({ type }) {
  const { Option } = Select;
  const { t } = useTranslation('translation');

  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const { status: userStatus, user } = useSelector((state) => state.user);
  const { roles } = useSelector((state) => state.role);

  const { vendors } = useSelector((state) => state.vendors);

  const [initialOrders, setInitialOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 100 });
  const [searchText, setSearchText] = useState('');
  const [activeVendors, setActiveVendors] = useState(vendors);
  const [vendorFilter, setVendorFilter] = useState('');
  const [invoiceFilter, setInvoiceFilter] = useState('');
  const [isCountedFilter, setIsCountedFilter] = useState('');
  const [isStockInFilter, setIsStockInFilter] = useState('');
  const [isDelayedFilter, setIsDelayedFilter] = useState('');
  const [statusFilter, setStatusfFilter] = useState([]);
  const [sort, setSort] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [handleDelayItemsLoading, setHandleDelayItemsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [barcodeButtonLoading, setBarcodeButtonLoading] = useState(false);

  const [showOverwriteWarning, setShowOverwriteWarning] = useState(false);

  const [selectedOrderStatuses, setSelectedOrderStatuses] = useState([]);

  const ObjectID = require("bson-objectid");

  const updatableFields = ["_id", "order_status", "quantity", "series", "cogs", "notes", "notes_admin",  "entered_without_invoice"];
  const conflictCheckIrrelevantFields = ["review_info", "confirm_info", "status_log"];

  const allOrderProductsItemsStatuses = ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES.map(item_status => {
    return {
      label: item_status,
      value: item_status
    }
  });

  const allLateProductsItemsStatuses = ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES.map(item_status => {
    return {
      label: item_status,
      value: item_status
    }
  });

  allOrderProductsItemsStatuses?.unshift({label: "All", value: "all"});
  allLateProductsItemsStatuses?.unshift({label: "All", value: "all"});

  usePrompt("You have unsaved changes, do you want to leave?", isDirty);

  useEffect(() => {
    fetchOrderAggregate({
      filter: [
        ADMIN_VALID_ORDER_PRODUCTS_ORDER_STATUSES,
        ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES,
      ],
      mode: "order_products_vendors"
    })
      .then(res => {
        setActiveVendors(res?.data[0]?.vendors);
      })
  }, []);

  useEffect(() => {
    setVendorFilter('')
    setInvoiceFilter('')
    setIsCountedFilter('')
    setIsStockInFilter('')
    setIsDelayedFilter('')
    setStatusfFilter('')
    setSearchText('')
    setSelectedOrderStatuses([])
    setSort(-1)
  }, [type])

  
  useEffect(() => {
    setLoading(true);

    const source = axios.CancelToken.source();

    fetchOrderAggregate({
      filter: [
        statusFilter,
        vendorFilter,
        invoiceFilter,
        isCountedFilter,
        isStockInFilter,
        isDelayedFilter,
        searchText,
        sort,
        pagination
      ],
      mode: type,
      cancelToken: source.token
    })
      .then((res) => {
        const filteredOrders = res.data?.filter(
          order => !changedItems?.find(
            item =>
              (item?.orderId?.toString() === order?._id?.toString()) &&
              item?.cartItems?.find(
                cart_item =>
                  (cart_item?.item?._id?.toString() === order?.cart_item?._id?.toString()) &&
                  cart_item?.item?.type === "delete"
              )
          )
        )

        setOrders(filteredOrders);
        setInitialOrders(filteredOrders);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    return () => {
      source.cancel('Operation canceled by the user.');
    }

  }, [type, sort, refresh, pagination, searchText, statusFilter, vendorFilter, invoiceFilter, isCountedFilter, isStockInFilter, isDelayedFilter]);

  const handleRefresh = () => {
    setOrders([]);
    setChangedItems([]);
    setIsDirty(false);
    setRefresh(r => !r);
  }

  const handleChangeCartItem = ({ item, field, value, initialItem }) => {
    let newOrders = orders.slice();
    // find index of original item
    let orderIndex = newOrders.findIndex((order) => order?.cart_item?._id === item._id);
    // apply updates and set orders

    changeCartItem({
      item: newOrders[orderIndex],
      setItem: (newOrder) => { newOrders[orderIndex] = newOrder },
      field,
      value,
      currentItem: item,
      series: seriesMain,
      isOrderStatus: true,
      initialItem,
      owner: user?.full_name
    });
    setOrders(newOrders);

    // find changed item for same orderId
    let relatedOrder = changedItems?.find(changedItem => changedItem?.orderId?.toString() === newOrders[orderIndex]?._id?.toString());
    // find item of changed item for same orderId and same cart_item id
    let relatedCartItem = null;
    if (relatedOrder) {
      relatedCartItem = relatedOrder?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === newOrders[orderIndex]?.cart_item?._id?.toString())
    }

    const updatedItem = newOrders?.find(o => o?.cart_item?._id?.toString() === item?._id?.toString())?.cart_item;

    const updateObj = {
      _id: newOrders[orderIndex]?.cart_item?._id,
      [field]: value
    }

    if (field === "series") {
      updateObj.item_count = updatedItem?.item_count;
    }

    if (field === "order_status") {
      updateObj.review_info = updatedItem?.review_info;
      updateObj.confirm_info = updatedItem?.confirm_info;
      updateObj.status_log = updatedItem?.status_log;
    }

    if (relatedOrder) {
      if (relatedCartItem) {
        // if related cart item exists, find it and replace with the new version
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems?.filter(cart_item => cart_item?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
              {
                item: {
                  ...relatedCartItem?.item,
                  ...updateObj,
                },
                type: relatedCartItem?.type === "clone" ? "clone" : "update"
              }
            ]
          }
        ])
      } else {
        // if relatedOrder exists, add updated item to its cartItems
        setChangedItems(items => [
          ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
          {
            orderId: relatedOrder?.orderId,
            cartItems: [
              ...relatedOrder?.cartItems,
              {
                item: updateObj,
                type: "update"
              }
            ]
          }
        ])
      }
    } else {
      // if relatedOrder doesn't exists, add a new changed item with deleted item
      setChangedItems(items => [
        ...items, 
        { 
          orderId: newOrders[orderIndex]?._id, 
          cartItems: [{ 
            item: updateObj, 
            type: "update" 
          }] 
        }
      ]);
    }

    setIsDirty(true);
  }

  const handleCloneCartItem = ({ item }) => {
    let newOrders = orders.slice();
    // find index of original item
    let orderIndex = newOrders.findIndex((order) => (order?._id === item?._id) && (order?.cart_item?._id === item?.cart_item?._id));
    // create a new id
    const newId = ObjectID()?.toHexString();

    // create a new cloned cart item and set
    newOrders = [
      ...newOrders?.slice(0, orderIndex + 1),
      { ...(newOrders[orderIndex]), cart_item: { ...(newOrders[orderIndex]?.cart_item), _id: newId } },
      ...newOrders?.slice(orderIndex + 1),
    ]
    setOrders(newOrders);

    // find changed item with same orderId
    const relatedOrder = changedItems?.find(changedItem => changedItem?.orderId?.toString() === item?._id?.toString());

    if (relatedOrder) {
      // if relatedOrder exists, add cloned item to its cartItems
      setChangedItems(items => [
        ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
        {
          orderId: relatedOrder?.orderId,
          cartItems: [
            ...relatedOrder?.cartItems,
            {
              item: { ...item?.cart_item, _id: newId },
              type: "clone",
              cloneReferenceItem: item?.cart_item?._id
            }
          ]
        }
      ])
    } else {
      // if relatedOrder doesn't exists, add a new changed item with cloned item
      setChangedItems(items => [...items, { orderId: item?._id, cartItems: [{ item: { ...item?.cart_item, _id: newId }, type: "clone", cloneReferenceItem: item?.cart_item?._id }] }]);
    }

    setIsDirty(true);
  }

  const handleDeleteCartItem = ({ item }) => {
    let newOrders = orders.slice();

    // filter and set current orders
    newOrders = newOrders.filter(order => order?.cart_item?._id !== item?.cart_item?._id);
    setOrders(newOrders);

    // find changed item for same orderId
    let relatedOrder = changedItems?.find(changedItem => changedItem?.orderId?.toString() === item?._id?.toString());
    // find item of changed item for same orderId and same cart_item id
    let relatedCartItem = null;
    if (relatedOrder) {
      relatedCartItem = relatedOrder?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === item?.cart_item?._id?.toString())
    }

    // if given item is cloned, filtering current state is enough, no need for api request
    const notDelete = relatedCartItem ? relatedCartItem?.type === "clone" : false;

    if (!notDelete) {
      if (relatedOrder) {
        // if related order exists, find it and replace with the new version
        if (relatedCartItem) {
          // if related cart item exists, find it and replace with the new version
          setChangedItems(items => [
            ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
            {
              orderId: relatedOrder?.orderId,
              cartItems: [
                ...relatedOrder?.cartItems?.filter(cart_item => cart_item?.item?._id?.toString() !== relatedCartItem?.item?._id?.toString()),
                {
                  item: { _id: item?.cart_item?._id },
                  type: "delete"
                }
              ]
            }
          ])
        } else {
          // if related cart item doesn't exists, simply add a new cartItem for changedItem with given item's cart_item id
          setChangedItems(items => [
            ...items?.filter(changedItem => changedItem?.orderId?.toString() !== relatedOrder?.orderId?.toString()),
            {
              orderId: relatedOrder?.orderId,
              cartItems: [
                ...relatedOrder?.cartItems,
                {
                  item: { _id: item?.cart_item?._id },
                  type: "delete"
                }
              ]
            }
          ])
        }
      } else {
        // if related order doesn't exists, simply add a new changedItem with given item's orderId
        setChangedItems(items => [...items, { orderId: item?._id, cartItems: [{ item: { _id: item?.cart_item?._id }, type: "delete" }] }]);
      }
    }
    setIsDirty(true);
  }

  const handleSave = ({ forceUpdate = false, conflictCheckOnly = true }) => {

    setSaveLoading(true);

    const beforeUpdateOrders = [];

    for (const order of changedItems) {
      let beforeUpdateOrder = { orderId: order?.orderId, cartItems: [] };

      for (const item of order?.cartItems) {

        const isClone = item?.type === "clone";
        const isDelete = item?.type === "delete";

        let orderItem;

        if (isClone) {
          orderItem = initialOrders?.find(o => o?._id?.toString() === order?.orderId?.toString() && o?.cart_item?._id?.toString() === item?.cloneReferenceItem?.toString());
        } else {
          orderItem = initialOrders?.find(o => o?._id?.toString() === order?.orderId?.toString() && o?.cart_item?._id?.toString() === item?.item?._id?.toString());
        }
        
        let beforeUpdateItem = { item: { _id: item?.item?._id }, type: item?.type };

        for (const key of ((isClone || isDelete) ? updatableFields : Object.keys(item?.item))) {
          if (!conflictCheckIrrelevantFields.includes(key)){
            beforeUpdateItem.item[key] = orderItem?.cart_item?.[key];
          }
        }

        beforeUpdateOrder.cartItems.push(beforeUpdateItem);
      }

      beforeUpdateOrders.push(beforeUpdateOrder);
    }

    // populate vendor field for clones items
    const populatedChangedItems = changedItems.map(item => {
      return {
        ...item, cartItems: item?.cartItems?.map(cartItem => {
          if( cartItem?.type === "clone" ) {
            return {
              ...cartItem, item: {
                ...cartItem?.item,
                product: cartItem?.item?.product
              }
            }
          }

          return cartItem;
        })
      }
    })

    fetchCalculateAndUpdate(populatedChangedItems, beforeUpdateOrders, forceUpdate, conflictCheckOnly)
      .then((res) => {
        setSaveLoading(false);

        if (res?.conflict) {

          const conflictedOrders = orders?.filter(order => 
            res?.nonConflictedCartItems.some(cartItem => order?.cart_item?._id === cartItem?.item?._id)
          );

          let codes = "";
          
          conflictedOrders?.map((order, index) => {
            codes += `${index + 1}-  ${order?.cart_item?.supplier_stock_code}<br />`;  
          })

          const message = t('fields.order_products.overwrite_notif') + "<br />" + codes || "";
          notification['error']({
            message: <div dangerouslySetInnerHTML={{ __html: message }} />,
          });


          
          handleSave({ forceUpdate: false, conflictCheckOnly: false });
          return;
        }
        
        setIsDirty(false);
        handleRefresh();
        notification['success']({
          message: 'Changes saved successfully',
        });

      })
      .catch((err) => {
        setSaveLoading(false);
        console.log(err)
        notification['error']({
          message: 'Changes couldn\'t saved successfully',
        });
      });
  }

  const handleProcessDelayedItems = () => {

    fetchProcessDelayedItems()
    .then((res) => {
      setHandleDelayItemsLoading(true);

      setIsDirty(false);
      //handleRefresh();
      const newDelayProductsExists = !!res?.data?.modifiedCount
      notification['success']({
        message: newDelayProductsExists ? `${res?.data?.modifiedCount} New Delayed Products are Updated`: 'There is no new Delayed Product',
      });
      setIsDelayedFilter(true)
      setHandleDelayItemsLoading(false);

    })
    .catch((err) => {
      setHandleDelayItemsLoading(false);

      notification['error']({
        message: 'Delayed Items couldn\'t updated successfully',
      });
    });

  }

  const handleBarcode = (barcodeUrl, productId, seriesName) => {
    setBarcodeButtonLoading(true);
    try {
      saveAs(barcodeUrl, `${productId}-${seriesName}`);
      notification['success']({
        message: "Barcode downloaded successfully!",
      });
      setBarcodeButtonLoading(false);
    } catch (error) {
      notification['error']({
        message: 'Error downloading barcode!',
      });
      setBarcodeButtonLoading(false);
    }
  }
  
  const onOrderStatusFilterChange = (checkedValues) => {
    const allStatuses = type === "late_products" ? allLateProductsItemsStatuses : allOrderProductsItemsStatuses;
    if(checkedValues?.includes("all")) {
        const allOrderStatuses = allStatuses?.map(option => option?.value);
        setSelectedOrderStatuses(allOrderStatuses);
        setStatusfFilter(allOrderStatuses?.filter(os => os !== "all"));
    } else if(selectedOrderStatuses?.includes("all")) {
        setSelectedOrderStatuses([]);
        setStatusfFilter([]);
    } else {
        setSelectedOrderStatuses(checkedValues);
        setStatusfFilter(checkedValues);
    }
  }

  return (<div className='order-products'>
    <Modal 
      title={t('fields.order_products.overwrite_warning_title')}
      width={800}
      visible={showOverwriteWarning}
      onCancel={() => setShowOverwriteWarning(false)}
      footer={[
        <Button
          key="ok" 
          type="secondary"
          onClick={() => { 
            setShowOverwriteWarning(false);
            handleSave({ forceUpdate: true, conflictCheckOnly: false });
          }}
        >
          {t('fields.order_products.save_and_overwrite')}
        </Button>,
        <Button 
          key="cancel" 
          type="primary"
          onClick={() => { 
            setShowOverwriteWarning(false);
            handleSave({ forceUpdate: false, conflictCheckOnly: false });
          }}
        >
          {t('fields.order_products.save_not_overwrite')}
        </Button>
      ]}
    >
      <p>{t('fields.order_products.overwrite_warning')}</p>
    </Modal>

    <div className='top-bar'>
      <h2>{type === "late_products" ? t(`home.Late Products`) : t(`home.Order Products`)}</h2>
      <div className='top-btns'>
       { type === "order_products" && 
        <Button 
            className='delayed-btn' 
            loading={handleDelayItemsLoading} 
            type='primary' 
            onClick={() => handleProcessDelayedItems()}
          >
            {t('buttons.Update Delayed Items')}
          </Button>
        }

        { type === "order_products" && 
          <Button 
            className='save-btn' 
            loading={saveLoading} 
            type='primary' 
            onClick={() => handleSave({ forceUpdate: false, conflictCheckOnly: true })}
          >
            {t('buttons.Save')}
          </Button>
        }

        { type === "order_products" && 
          <Button 
            className='discard-btn' 
            type='primary' 
            onClick={() => {
              if (window.confirm('Do you want do discard all changes?')) handleRefresh();
            }}
          >
            {t(`buttons.Discard Changes`)}
          </Button>
        }
      </div>
    </div>
    <div className='form-label-bar'>
      <div className='search-bar-empty'></div>
      <div className='form-bar-select'>
        {t(`home.vendor`)}
      </div>
      <div className='form-bar-select' >
        {t(`fields.order_products.entered_without_invoice`)}
      </div>

      <div className='form-bar-select' >
        {t(`fields.edit_order.Is Counted`)}
      </div>

      <div className='form-bar-select' >
        {t(`fields.edit_order.Is StockIn`)}
      </div>

      <div className='form-bar-select' >
        {t(`fields.edit_order.Is Delayed`)}
      </div>
      <div className='form-bar-select'>
        {t(`sort.Sort`)}
      </div>

    </div>
    <div className='form-bar'>
      <Input className='search-bar' value={searchText} placeholder={t('placeholders.Search')} onChange={(e) => setSearchText(e.target.value)} />
      
      <Select showSearch className='form-bar-select' placeholder={t('home.Vendor')} defaultValue={''}  value={vendorFilter} onChange={setVendorFilter}>
        <Option key={'any'} value={''}>{t(`status.Any`)}</Option>
        {activeVendors?.slice()
          ?.sort((a, b) => a?.name?.localeCompare(b?.name))
          ?.map((item) => (
            <Option key={item?.name} value={item?.name}>{item?.name}</Option>
          ))}
      </Select>

      <Select className='form-bar-select' defaultValue={''} value={invoiceFilter} onChange={setInvoiceFilter}>
        <Option key='all' value=''>{t(`status.Any`)}</Option>
        <Option key='entered-with-invoice' value={true}>{t(`sort.Entered With Invoice`)}</Option>
        <Option key='entered-without-invoice' value={false}>{t(`sort.Entered Without Invoice`)}</Option>
      </Select>

      <Select className='form-bar-select' defaultValue={''} value={isCountedFilter} onChange={setIsCountedFilter}>
        <Option key='all' value=''>{t(`status.Any`)}</Option>
        <Option key='is-counted' value={true}>{t(`sort.Entered With Invoice`)}</Option>
        <Option key='not-is_counted' value={false}>{t(`sort.Entered Without Invoice`)}</Option>
      </Select>

      <Select className='form-bar-select' defaultValue={''}  value={isStockInFilter} onChange={setIsStockInFilter}>
        <Option key='all' value=''>{t(`status.Any`)}</Option>
        <Option key='is-stockIn' value={true}>{t(`sort.Entered With Invoice`)}</Option>
        <Option key='not-stockIn' value={false}>{t(`sort.Entered Without Invoice`)}</Option>
      </Select>

      <Select className='form-bar-select' defaultValue={''} value={isDelayedFilter} onChange={setIsDelayedFilter}>
        <Option key='all' value=''>{t(`status.Any`)}</Option>
        <Option key='is-delayed' value={true}>{t(`sort.Delayed`)}</Option>
        <Option key='not-delayed' value={false}>{t(`sort.Not Delayed`)}</Option>
      </Select>
      <Select className='form-bar-select' placeholder='Sort' value={sort} defaultValue={-1} onChange={setSort}>
        <Option key='new-to-old' value={-1}>{t(`sort.New to Old`)}</Option>
        <Option key='old-to-new' value={1}>{t(`sort.Old to New`)}</Option>
      </Select>
    </div>

    <div className='form-label-bar-second-row'>
      <div className='form-bar-select-second-row'>
        {t(`home.status`)}
      </div>

    </div>

    <div className='form-bar-second-row'>
      <Select
            className='form-bar-select-second-row'
            mode="multiple"
            allowClear
            placeholder="Select Order Status"
            value={selectedOrderStatuses}
            onChange={onOrderStatusFilterChange}
            options={type === "late_products" ? allLateProductsItemsStatuses : allOrderProductsItemsStatuses}
            maxTagCount="responsive"
            showArrow
      />
    </div>

    <div className='product-table'>
      {loading && <Spin tip={t(`placeholders.Loading Products...`)} />}
      {orders?.map((item, index) => {

        const hasChanged = changedItems?.find(changedItem => changedItem?.orderId?.toString() === item?._id && changedItem?.cartItems?.find(cart_item => cart_item?.item?._id?.toString() === item?.cart_item?._id?.toString()));
        const unitPrice = calculateItemPrice({ item: item?.cart_item, order: true });
        const totalPrice = loncaRound(unitPrice * item?.cart_item?.item_count * item?.cart_item?.quantity);

        const itemDate = item?.cart_item?.order_addition_date ? item?.cart_item?.order_addition_date : item?.payment_at;

        const shouldMarkDuplicates = isRoleHavePermission(['Warehouse'], user?.admin_role)
        const isProductDuplicate = shouldMarkDuplicates && item?.is_older_duplicate
 
        return (
          <div className={`product-container ${hasChanged && 'changed'} ${isProductDuplicate && 'disabled'}`} key={item._id + index}>
            {isProductDuplicate ? <div className='overlay-warning' text>{t(`home.Older Duplicate Product`)}  </div> : null}
            <div className='product-image-container'>
              <ResponsiveImage className='product-image' height={400} src={item?.cart_item?.productMainImage} alt="product" />
            </div>
            <div className='product-info'>

              <div className='product-info-row'>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.product.Customer Name`)}</span>
                  <input className='ant-input' placeholder='Customer Name' value={item?.status === "Cancelled" ? "Cancelled" : item?.customer_name} disabled />
                </div>
                <div className='product-info-item'>
                  <span>{t(`fields.product.unique_id`)}</span>
                  <input className='ant-input' placeholder='Unique Order ID' value={item?.unique_order_id} disabled />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'> {t(`fields.order_products.Payment At`)} </span>
                  <input className='ant-input' placeholder='Payment Date' value={loncaFormatDate(itemDate)} disabled />
                </div>
                <div className='product-info-btns'>

                  <div className="discounted-tag">
                    {item?.status === "Cancelled" ? <span>{t(`fields.order_status.Cancelled`)}</span> : null}
                    {item?.cart_item?.is_discounted ? <span>{t(`home.Discounted`)}</span> : null}
                    {item?.first_product ? <span style={{ "color": "rgb(3, 70, 177)" }} text>{t(`home.First Product`)}  </span> : null} { }

                  </div>
                  <div className="product-info-buttons">
                    { type === "order_products" && <button className={`delete-button`} onClick={() => handleDeleteCartItem({ item })}>{t('home.Delete')}</button> }
                    { type === "order_products" && <button className={`clone-button`} onClick={() => handleCloneCartItem({ item })}>{t('home.Clone')}</button> }
                  </div>
                </div>
              </div>


              <div className='product-info-row'>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t('home.vendor')}</span>
                  <input className='ant-input' placeholder='Vendor' value={item?.cart_item?.vendorName} disabled />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.product.Product Name`)}
                    <span
                      className='copy-icon'
                      onClick={() => navigator.clipboard.writeText(item?.cart_item?.productName)}
                    >
                      <CopyOutlined />
                    </span>
                  </span>
                  <TextArea rows={2} disabled value={item?.cart_item?.productName} />
                </div>

                <div className='product-info-item'>
                  <span>{t(`fields.product.Supplier Stock Code`)}</span>
                  <input className='ant-input' placeholder='Stock Code' value={item?.cart_item?.productSupplierStockCode} disabled />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'> {t(`fields.order_products.Checkout Date`)} </span>
                  <input className='ant-input' placeholder='Checkout Date' value={loncaFormatDate(item?.checkout_date)} disabled />
                </div>
                
              </div>

              <div className='product-info-row'>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.product.Status`)}</span>
                  <Select 
                    placeholder='Order Status' 
                    value={item?.cart_item?.order_status} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))} 
                    onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'order_status', value: e, initialItem: initialOrders?.filter(initialItem => initialItem?.cart_item?._id === item?.cart_item?._id)[0]?.cart_item })
                    }
                  >
                    {
                      ADMIN_VALID_ORDER_PRODUCTS_ITEM_STATUSES.map((status) => {
                        return <Option key={status} value={status}>{t(`status.${status}`)}</Option>
                      })}
                  </Select>
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.product.Series`)}</span>
                  <Select 
                    placeholder='Series' 
                    showSearch 
                    value={item?.cart_item?.series} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))} 
                    onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'series', value: e })
                    }
                  >
                    { seriesMain.map((serie) => (
                      <Option key={serie.name} value={serie.name}>
                        {serie.name}
                      </Option>
                    )) }
                  </Select>
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.order_products.Quantity`)}</span>
                  <input 
                    className='ant-input' 
                    placeholder='Quantity' 
                    value={item?.cart_item?.quantity} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))} 
                    onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'quantity', value: e.target.value })
                    }
                  />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.order_products.Item Count`)}</span>
                  <input className='ant-input' placeholder='Item Count' value={item?.cart_item?.item_count} disabled />
                </div>

              </div>


              <div className='product-info-row'>
                
                {item?.cart_item?.order_status === 'StockIn' ? 
                <div className='product-info-item'>
                  <span>{t(`fields.product.Stock Box Number`)}</span>
                  <input className='ant-input' placeholder='Stock Box Number' value={item?.cart_item?.stock_box_id} disabled />
                </div>  : null}

                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.order_products.COGS`)}</span>
                  <input 
                    className={`ant-input`} 
                    placeholder='COGS' 
                    value={renderCalculatedVendorCogs({
                        payment_at: item?.payment_at, 
                        cogs: item?.cart_item?.cogs, 
                        rate_usd_tr_buying: item?.rate_usd_tr_buying, 
                        rate_usd_buying: item?.rate_usd_buying, 
                        rate_usd_selling: item?.rate_usd_selling, 
                      })} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))} 
                    onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'cogs', value: e.target.value })
                    } 
                  />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.order_products.Unit Price`)}</span>
                  <input className='ant-input' placeholder='Unit Price' value={`$${unitPrice}`} disabled />
                </div>

                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.order_products.Total Price`)}</span>
                  <input className='ant-input' placeholder='Total Price' value={`$${totalPrice}`} disabled />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>{t(`fields.product.Supplier Link`)}
                    <span
                      className='copy-icon'
                      onClick={() => navigator.clipboard.writeText(item?.cart_item?.productSupplierLink)}
                    >
                      <CopyOutlined />
                    </span>
                  </span>
                  <TextArea rows={2} disabled value={item?.cart_item?.productSupplierLink} />
                </div>
              </div>

              <div className='product-info-row'>
                <div className='product-info-item'>
                  <span>{t(`fields.order_products.entered_without_invoice`)}</span>
                  <input 
                    style={{ "marginTop": "5px" }} 
                    type="checkbox" 
                    value={item?.cart_item?.entered_without_invoice} 
                    checked={item?.cart_item?.entered_without_invoice} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))}
                    onChange={(e) => {
                      handleChangeCartItem({ item: item?.cart_item, field: 'entered_without_invoice', value: e.target.checked })
                    }}
                  />
                </div>

                <div className='product-info-item'>
                  <span>{t(`fields.edit_order.Is Counted`)}</span>
                  <input 
                    style={{ "marginTop": "5px" }} 
                    type="checkbox" 
                    value={item?.cart_item?.is_counted} 
                    checked={item?.cart_item?.is_counted} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))}
                    onChange={(e) => {
                      handleChangeCartItem({ item: item?.cart_item, field: 'is_counted', value: e.target.checked })
                    }}
                  />
                </div>

                <div className='product-info-item'>
                  <span>{t(`fields.edit_order.Is Delayed`)}</span>
                  <input 
                    style={{ "marginTop": "5px" }} 
                    type="checkbox" 
                    value={item?.cart_item?.is_delayed} 
                    checked={item?.cart_item?.is_delayed} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))}
                    onChange={(e) => {
                      handleChangeCartItem({ item: item?.cart_item, field: 'is_delayed', value: e.target.checked })
                    }}
                  />
                </div>

                {item?.cart_item?.is_stock_in && <div className='product-info-item'>
                  <span>{t(`fields.edit_order.Is StockIn`)}</span>
                  <input 
                    style={{ "marginTop": "5px" }} 
                    type="checkbox" 
                    value={item?.cart_item?.is_stock_in} 
                    checked={item?.cart_item?.is_stock_in} 
                    disabled={type === "late_products" || (ADMIN_DISABLED_ITEM_STATUSES.includes(item?.cart_item?.order_status) && ADMIN_DISABLED_ORDER_STATUSES.includes(item.status))}
                    onChange={(e) => {
                      handleChangeCartItem({ item: item?.cart_item, field: 'is_stock_in', value: e.target.checked })
                    }} 
                  />
                </div>}

                <div className='product-info-item'>
                  <span>{t(`fields.product.order_total_item`)}</span>
                  <input className='ant-input' placeholder='Order Total Item' value={item?.item_number} disabled />
                </div>

                <div className='product-info-item product-notes'>
                  <span className='product-info-label'>{t(`fields.order_products.Supplier Notes`)}</span>
                  <TextArea 
                    rows={1} 
                    value={item?.cart_item?.notes} 
                    disabled
                  />
                </div>
                
                <div className='product-info-item product-notes'>
                  <span className='product-info-label'>{t(`fields.order_products.Admin Notes`)}</span>
                  <TextArea 
                    rows={1} 
                    onChange={
                      (e) => handleChangeCartItem({ item: item?.cart_item, field: 'notes_admin', value: e.target.value })
                    } 
                    value={item?.cart_item?.notes_admin} 
                    disabled={type === "late_products"}
                  />
                </div>

                <div className='product-info-item'>
                  <span className='product-info-label'> {t('fields.order_products.Warehouse Box')}</span>
                  <input className='ant-input' placeholder={t('fields.order_products.Warehouse Box')}value={item?.warehouse_box} disabled />
                </div>

                {item?.cart_item?.order_addition_date &&
                  <div className='product-info-item'>
                    <span className='product-info-label'> {t('fields.order_products.Order Addition Date')}</span>
                    <input className='ant-input' placeholder='Order Addition Date' value={loncaFormatDate(item?.cart_item?.order_addition_date)} disabled />
                  </div>
                }
                
                {item?.cart_item?.status_log?.map((status_log) => {
                  if(status_log?.status === "Confirmed") {
                    return (
                     <>
                        <div className='product-info-item'>
                          <span className='product-info-label'> {t('fields.order_products.Confirm Date')}</span>
                          <input className='ant-input' placeholder='Confirm Date' value={loncaFormatDate(status_log?.date)} disabled />
                        </div>
                        <div className='product-info-item'>
                          <span className='product-info-label'> {t('fields.order_products.Confirm Person')}</span>
                          <input className='ant-input' placeholder='Confirm Person' value={status_log?.owner} disabled />
                        </div>
                       </>
                     )
                   }
                  if(status_log?.status === "Reviewed") {
                    return (
                     <>
                         <div className='product-info-item'>
                          <span className='product-info-label'> {t('fields.order_products.Review Date')}</span>
                          <input className='ant-input' placeholder='Review Date' value={loncaFormatDate(status_log?.date)} disabled />
                        </div>
                        <div className='product-info-item'>
                          <span className='product-info-label'> {t('fields.order_products.Review Person')}</span>
                          <input className='ant-input' placeholder='Review Person' value={status_log?.owner} disabled />
                        </div>
                       </>
                     )
                   }
                  if(status_log?.status === "Wrong / Missing Items") {
                   return (
                    <>
                        <div className='product-info-item'>
                          <span className='product-info-label'> Wrong/Missing Date</span>
                          <input className='ant-input' placeholder='Review Date' value={loncaFormatDate(status_log?.date)} disabled />
                        </div>
                        <div className='product-info-item'>
                          <span className='product-info-label'> Wrong/Missing Person</span>
                          <input className='ant-input' placeholder='Review Person' value={status_log?.owner} disabled />
                        </div>
                      </>
                    )
                  }
                })}             
                {item?.cart_item?.status_log?.length > 0 
                ? <>
                <div className='product-info-item'>
                  <span className='product-info-label'>Last Status Change</span>
                  <input className='ant-input' value={item?.cart_item?.status_log?.[item?.cart_item?.status_log?.length - 1]?.status} disabled />
                </div>
                <div className='product-info-item'>
                  <span className='product-info-label'>Last Status Changed By</span>
                  <input className='ant-input' value={item?.cart_item?.status_log?.[item?.cart_item?.status_log?.length - 1]?.owner} disabled />
                </div>
                </> 
                : null}
              </div>
              <div className='product-info-row'>
                <div className='product-info-item'>
                  {item?.cart_item?.barcode_url && <Button 
                    onClick={() => handleBarcode(item?.cart_item?.barcode_url, item?.cart_item?.product, item?.cart_item?.series)}
                    loading={barcodeButtonLoading}
                    disabled={barcodeButtonLoading}>
                    Download Barcode
                  </Button>}
                </div>
              </div>
            </div>
          </div>

        )
      })}
      <Pagination filter={pagination} setFilter={setPagination} />
    </div>
  </div>

  )
}

export default OrderProducts;